import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { styled, media } from 'components/Theme/Styles';
import Headline from './Headline';
import Cta from './Cta';
import variablesToProps from 'lib/variablesToProps';

const itemSpacing = (keyword) => {
  switch (keyword) {
    case 'Thirds':
      return 'width:33%'
    case 'Forths':
      return 'width:25%'
    default:
      return 'width:33%'
  }
}

const Items = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width:100%;
  flex-wrap: ${p => p.itemSpacing === 'Space Evenly' ? 'nowrap' : 'wrap' };
  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`;

const Item = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: ${p => p.align ? p.align : 'center'};
  align-items: ${p => p.align ? p.align : 'center'};
  text-align: ${p => p.align ? p.align : 'center'};
  margin-bottom: ${p => p.theme.spacing(p.marginBottom)}rem;
  margin-top: ${p => p.theme.spacing(p.marginTop)}rem;

  ${p => itemSpacing(p.itemSpacing) };

  & > div {
    padding:${p => p.padding};
  }


  ${p => p.innerBorders ? `
    &:nth-child(3n-1) {
      border-right: 0.1rem solid ${p.theme.grey};
      border-left: 0.1rem solid ${p.theme.grey};
    }
  ` : null};


  ${media.lessThan("tablet")`
    width:100%;
    padding:0;
    height:auto;
    border-left:0 !important;
    border-right:0 !important;
    
  `}
`;

const Image = styled(GatsbyImage)`
    max-width:20rem;
`;

class List extends Component {

  render() {
    const { items, spacing, marginTop, marginBottom } = this.props
    
    let itemProps = this.props.list && this.props.list.item
    let itemsProps = this.props.list && this.props.list.items

    return (
      <Fragment>
        <Items itemSpacing={spacing} {...itemsProps}>
          {(items || []).map((item, i) => (
            <Item key={`item_${i}`} itemSpacing={spacing} marginTop={marginTop} marginBottom={marginBottom} {...itemProps}>
              <div>
                { item && item.asset &&
                  <Image key={`asset_${i}`} image={item.asset.gatsbyImageData} alt={item.asset.description} />
                }

                {(item.headlines || []).map((headline, i) => {
                  return <Headline key={`headline_${i}`} {...headline} />
                }) }

                {item.cta &&
                  <Cta {...item.cta} />
                }
              </div>
            </Item>
          ))}
        </Items>
      </Fragment>
    )
  }
}

export default variablesToProps('list')(List);

export const listFragment = graphql`
  fragment List on ContentfulSectionList {
    __typename
    spacing
    marginTop
    marginBottom
    items {
      asset {
        gatsbyImageData
      }

      assetSize

      headlines {
        ...Headline
      }
      cta {
        ...Cta
      }
    }
    variables {
      key
      value
    }
  }
`;
