import React, { Component, Fragment } from 'react'
import HamburgerNav, { HamburgerNavItem } from './HamburgerNav';
import { styled } from 'components/Theme/Styles';
import { GatsbyImage } from "gatsby-plugin-image";
import Link from 'components/Theme/Link';

const ModelNumber = styled.div`
  font-size:1.5rem;
  color:#92A5B1;
`;

const Deets = styled.div`
  position:relative;
  top:0.5rem;
  white-space:pre-line;
`;

const CategoryDeets = styled(Deets)`
  font-weight:200;
  color:#fff;
  font-size:1.7rem;
  margin:3rem 0;
`

const ProductImage = styled.div`
  width:10rem;
  position:relative;
`;

const Row = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  margin-top:0.5rem;
`;

const NavImage = styled(GatsbyImage)`
  width:10rem;
  height:10rem;
`;


const CategoryName = ({category}) => (
  <HamburgerNavItem depth={4}>
    <Row>
      <CategoryDeets>
        <Link to={category.handle}>{category.name}</Link>
      </CategoryDeets>
    </Row>
  </HamburgerNavItem>
)

const NavItems = ({category}) => {
  return (category.lists || []).map((list, ii) => {
    return list.products.map((product, iii) => {
      return (
        <HamburgerNavItem depth={4} key={`mobileProduct_${iii}`} to={`/products/${product.handle}`}>
          <Row>
            <ProductImage>
            { product.images && 
              <NavImage image={product.images[0].gatsbyImageData} alt={product.title} /> }
            </ProductImage>
            <Deets>
              {product.title}
              <ModelNumber>{product.variants[0].sku}</ModelNumber>
            </Deets>
          </Row>
        </HamburgerNavItem>
      )
    })
  })
}


class ProductMenu extends Component {

  render() {
    const { navigationData } = this.props;

    return (
      <Fragment>
        <HamburgerNav text="Products" open={true}>
        {(navigationData || []).map((topLevel, i) => {
          
            return (
              <li key={`li_${i}`}>
                <HamburgerNav text={topLevel.topLevelName} key={`topLevel_${i}`} depth={2}>
                {

                  (topLevel.categories || []).map((category, iii) => (
                    <div>
                      <CategoryName category={category} />
                      <NavItems category={category} />  
                    </div>
                  ))}
                </HamburgerNav>
              </li>
            )
          })
        }
        </HamburgerNav>
      </Fragment>
    );
  }
}

export default ProductMenu;
