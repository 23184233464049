import React, { Component, Fragment } from 'react';
import { styled } from 'components/Theme/Styles';
import ThemeHeadline from 'components/Theme/Headline';
import ThemeBlock, { Section } from 'components/Theme/Block';
import Overflow from 'components/Theme/Overflow';
import { GatsbyImage } from "gatsby-plugin-image"
import _ from 'lodash';
import Link from 'components/Theme/Link';

const SpecTable = styled.table`
  min-width:100%;
  font-size:${p => p.theme.h4FontSize}rem;
  font-weight:300;
  padding:0;
  border-collapse:collapse;
  table-layout: fixed;
  flex:1;
  tr:nth-child(even) {background-color: ${p => p.theme.mediumWhite}}
`;

const SpecRow = styled.tr`

`;


const SpecCol = styled.td`
  text-align:${p => p.align};
  padding:1.5rem;
  margin:0;
  ${p => p.withBorders && `border-right: 0.1rem solid ${p.theme.grey}` };
  border-spacing:0;
  &:first-child {
    text-align:left;
    padding-left:1.5rem;
  }
`;


const Headline = styled(ThemeHeadline)`
  font-size:${p => p.theme.h3FontSize * 1.4}rem;
`;

const ScrollHelpText = styled.div`
  font-size:${p => p.theme.h5FontSize}rem;
  color:${p => p.theme.darkGray};
  margin-top:2rem;
`;

class CompareModels extends Component {

  constructSpecsTable = (products) => {
    let keys = []
    let table = []

    products.map((product) => {
      return product.specs && product.specs.map((spec) => {
        return keys.push(spec.key)
      })
    })


    keys = _.uniq(keys)
    table.push(keys)

    products.map((product) => {
      let col = []

      keys.map((key) => {
        let findByKey = _.find(product.specs, ['key', key])
        let specValue = findByKey && findByKey.value
        return  col.push(specValue || null)
      })

      return table.push(_.concat(col))
    })

    return table
  }


  render() {
    const { models } = this.props

    let products = models && models.products
    let cols = this.constructSpecsTable(products || [])
    let rows = _.first(cols)

    const Row = (props) => {
      return (
        <SpecRow>
          {(cols || []).map((col, i) => {
            return <Col key={`col_${i}`} spec={col[props.index]}/>
          })}
        </SpecRow>
      )
    }

    const Col = (props) => {

      return (
        <SpecCol align="center" withBorders>
          {props.spec || '-'}
        </SpecCol>
      )
    }

    return (
      <Fragment>
        <ThemeBlock height="Auto" id="compareModels">
          <Section justify="Top" innerPadding="Large">
            <Overflow>
              <SpecTable>
                <tbody>
                <SpecRow>
                  <SpecCol>
                    <Headline h2>Compare Models</Headline>
                  </SpecCol>
                  {(products || []).map((product, i) => {
                    return (
                      <SpecCol align="center" key={`model_${i}`}>
                        <Link to={product.slug}>
                          {product.pageImage && product.pageImage &&
                            <GatsbyImage image={product.pageImage.gatsbyImageData} imgStyle={{objectFit: 'contain'}} style={{
                              width: "20vw",
                              height: "20rem",
                              margin: "0 auto",
                            }} />
                          }


                          <ThemeHeadline h4>{product.name}</ThemeHeadline>
                          <ThemeHeadline h5>{product.modelNumber}</ThemeHeadline>
                        </Link>
                      </SpecCol>
                    )
                  })
                  }
                </SpecRow>
                {(rows || []).map((row, i) => {
                  return <Row key={`row_${i}`} index={i} />
                })}
                </tbody>
              </SpecTable>
            </Overflow>
            <ScrollHelpText>⟵ scroll ⟶</ScrollHelpText>
          </Section>
        </ThemeBlock>
      </Fragment>
    )
  }
}

export default CompareModels;
