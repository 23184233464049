import React, { Component } from 'react'
import { styled, css } from 'components/Theme/Styles';
import HamburgerMenu from './MobileNav/HamburgerMenu';
import Logo from 'components/Theme/Logo';
import { Link } from 'gatsby';
import ContactUs from './DesktopNav/ContactUs'

import { Email } from 'styled-icons/material';

let height = '8.5rem';
let IconSize = 3;
let LineLeft = IconSize / 3.2;
let LineOffset = IconSize / 2;
let LineWidth = 1.8;

const HeaderWrapper = styled.div`
  overflow:hidden;  
  overflow-y:auto;
  position:sticky;
  z-index:100;
  width:100vw;
  top:0;
  height:auto;  
  background-color:${p => p.theme.black};
`

const Header = styled.div`
  width: 100%;
  color: ${p => p.theme.white};
  background-color:${p => p.theme.black};
  height:${height};
`;

const TopBar = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: ${height};
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:96%;
`;

const NavBarLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  
  
`;

const ListItem = styled.li`
  color: white;
  font-size: ${p => p.theme.navItemFontSize}rem;
  padding: 0;
  font-weight: 300;
  font-style: normal;
  display:flex;
  justify-content: center;
  align-content: center;
  flex-direction:row;  
  &:hover {
    color: inherit;
  }
`;


const NavBarCenter = styled.div`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position:relative;
  top:0.2rem;
  display:flex;
`;

const NavBarRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right:1rem;
`;

const HamburgerMenuIcon = styled.div`
  cursor: pointer;
  position: relative;
  top:-0.2rem;
  width: ${IconSize}rem;
  height: ${IconSize}rem;
  transition: all 0.25s;
  transform: ${p => p.open ? 'rotate(90deg)' : null };
  z-index:105;
  
  
  span {
    background-color:#fff;
  }
`;

const HamburgerLine = css`
  content: '';
  display: block;
  position: absolute;
  left: ${LineLeft}rem;
  width: ${LineWidth}rem;
  height: 0.2rem;
  transform: rotate(0);
  transition: all 0.25s;
`;

const TopLine = styled.span`
  ${HamburgerLine};
  top: ${LineOffset - 0.2}rem;
  transform: ${p => p.open ? 'rotate(45deg) translateY(0rem)' : 'translateY(-0.2rem)' };
`;

const BottomLine = styled.span`
  ${HamburgerLine};
  bottom: ${LineOffset}rem;
  transform: ${p => p.open ? 'rotate(-45deg) translateY(0rem)' : 'translateY(0.7rem)' };
`;

const ProluxeLogo = styled(Logo)`
  
`;


class MobileNav extends Component {

  state = {
    open: false
  }

  toggle = () => {
    this.setState({ open: !this.state.open }, () => {
      this.state.open ? this.isOpen() : this.isClosed()
    });
  };

  componentDidMount() {
    // 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
    this.header = typeof document !== 'undefined' ? document.querySelector('#mobileHeader') : null
    this.main = typeof document !== 'undefined' ? document.querySelector('#main') : null
    this.props.open ? this.isOpen() : this.isClosed()
  }


  isOpen = () => {
    this.main.style.position = 'fixed';
    this.header.style.overflowY = 'auto';
    this.header.style.height = '100vh';
  }

  isClosed = () => {
    this.main.style.position = 'unset';
    this.header.style.overflow = 'hidden';
    this.header.style.height = null;
  }

  render() {
    const { navigation, className, floating, inverse } = this.props

    return (
      <HeaderWrapper className={className}
                     floating={floating}
                     menuOpen={this.state.open}>
        <Header id="mobileHeader"
                menuOpen={this.state.open}
                inverse={inverse}
                floating={floating}>
          <TopBar>
            <NavBar>
              <NavBarLeft>
                
                  <HamburgerMenuIcon open={this.state.open} onClick={this.toggle} inverse={inverse}>
                    <TopLine open={this.state.open} />
                    <BottomLine open={this.state.open} />
                  </HamburgerMenuIcon>
                
              </NavBarLeft>

              <NavBarCenter>
                
                  <ProluxeLogo color={'white'} />
                
              </NavBarCenter>

              <NavBarRight>
                
                  <Link to="/pages/contact-us"><Email color="#fff" height="2.5rem"/></Link>
                
              </NavBarRight>
            </NavBar>
          </TopBar>

          <HamburgerMenu open={this.state.open} navigation={navigation} />
          
        </Header>
      </HeaderWrapper>
    );
  }
}



export default MobileNav;
