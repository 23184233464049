import React, { Component } from 'react';
import Link from 'components/Theme/Link';
import { styled } from 'components/Theme/Styles';

const Item = styled.li`
  padding:1.5rem 1.5rem 1rem 1.5rem;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  text-indent:3rem;

  a {
    color: #fff;
    font-size:${p => p.theme.h4FontSize}rem;
  }

  ${p => p.depth && `
    text-indent:${p.depth * 1}rem;
    padding: 0.5rem 0 0.5rem 0;  
    // a {
    //   font-size:${p.theme.h5FontSize}rem;
    // }
  `};
`;

class HamburgerNavItem extends Component {
  render() {
    return (
      <Item depth={this.props.depth}>
        { this.props.to ? <Link to={this.props.to || ''}>{this.props.children}</Link> : this.props.children }
      </Item>
    );
  }
}

export default HamburgerNavItem;
