import React, { Component } from 'react';
import Config from '../../config';
import variablesToProps from 'lib/variablesToProps';
import Helmet from 'react-helmet';

class SchemaArticle extends Component {


  render() {
    const {
      title,
      description,
      pageImage,
      createdAt,
      updatedAt,
      schemaArticle
    } = this.props

    const {
      author
    } = schemaArticle

    let image = pageImage && pageImage.file && pageImage.file.url;

    let schema = {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        "@context": "http://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": `${Config.get("currentUrl")}`
        },
        "headline": `${title}`,
        "image": `${image}`,
        "datePublished": `${createdAt}`,
        "dateModified": `${updatedAt}`,
        "author": {
          "@type": "Person",
          "name": `${author}`
        },
        "publisher": {
          "@type": "Organization",
          "name": `${Config.get("schemaOrganization").name}`,
          "logo": {
            "@type": "ImageObject",
            "url": `${Config.get("schemaOrganization").logo}`
          }
        },
        "description": `${description}`
      })
    };

    return (
      <Helmet script={[schema]} />
    )
  }
}


export default variablesToProps('schemaArticle')(SchemaArticle);
