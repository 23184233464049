import { Component } from 'react';
import { graphql } from 'gatsby'

class Background extends Component {

  static props = (background) => {
    let backgroundImage = background?.image?.gatsbyImageData
    let backgroundVideo = background?.video?.file.url
    let backgroundColor = background && background.color
    let backgroundOpacity = background && background.opacity
    let backgroundMargin = background && background.xyMargin
    
    return {  backgroundColor: backgroundColor,
              backgroundImage: backgroundImage,
              backgroundVideo: backgroundVideo,
              backgroundMargin: backgroundMargin,
              backgroundOpacity: backgroundOpacity }
  }
}

export default Background;

// language=GraphQL
export const backgroundFragment = graphql`
  fragment Background on ContentfulSectionBackground {
    xyMargin
    video {
      file {
        url
      }
    }
    image {
      gatsbyImageData
    }
    color
    opacity
  }
`;
