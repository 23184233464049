import React, { Component, Fragment } from 'react'
import { styled, media } from 'components/Theme/Styles';

const Table = styled.table`
  width:100%;
  font-size:${p => p.theme.h4FontSize}rem;
  font-weight:300;
  padding:0;
  border: 0.1rem solid ${p => p.theme.grey};
  border-collapse:collapse;
  margin:2.5rem 0;

  tr:nth-child(even) {background-color: ${p => p.theme.mediumWhite}}

  tr td {
    padding:1.5rem;
    margin:0;
    border:0;
    border: 0.1rem solid ${p => p.theme.grey};
    border-spacing:0;
    text-align: left;
    &:last-child {
      text-align: center;
    }

    ${media.lessThan("tablet")`
      &:first-child {
        text-indent: 0;
        text-align:left;
      }

      &:last-child {
        text-align: right;
      }
      border:0;
    `}
  }

  ${media.lessThan("tablet")`
    border-left:0;
  `}
`;

class SpecTable extends Component {

  render() {
    const { specs,  } = this.props

    return (
      <Table>
        <tbody>
          {(specs || []).map((spec, i) => {
            return (
              <tr key={`spec_${i}`}>
                <td>{spec.key}</td>
                <td>{spec.value}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
}

export default SpecTable;
