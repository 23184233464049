import React, { Fragment, Component } from 'react';
import { styled, media } from 'components/Theme/Styles';
import ThemeHeadline from 'components/Theme/Headline';
import RichText from 'components/Theme/RichText';
import { KeyboardArrowRight } from 'styled-icons/material'
import { keyframes } from '../Theme/Styles'

const Items = styled.div`
  width:100%;
  text-align:left;
`;

const ItemsWrapper = styled.div`
  width:100%;
  font-weight:300;
  margin-bottom:5rem;

`;

const Question = styled.div`
  width:100%;
  cursor:pointer;
  border-bottom:0.1rem solid ${p => p.theme.grey};
  position:relative;
  padding-right:3rem;
`;

const Answer = styled.div`
  transition: all 0.4s ease;
  animation: ${p => p.isActive ? Reveal : Disappear } 0.45s ${p => p.isActive ? 'normal' : 'alternate' } ease-in-out both 1;
  max-height:${p => p.isActive ? 'auto' : 0 };
  height:auto;
  overflow:hidden;
  margin-top:1rem;
`;

const Reveal = keyframes`
  0% {
    // transform:scale(0.9) rotateX(-60deg);
    // transform-origin: 50% 0;
    transform: translateY(-10px);
    opacity:0;
  }
  100% {
    opacity:1;
    // transform:scale(1);
    transform: translateY(0px);
  }
`;

const Disappear = keyframes`
  0% {
    opacity: 1;
    // transform:scale(1);
    transform: translateY(0px);
  }
  100% {
    opacity:0;
    // transform:scale(0.9) rotateX(-60deg);
    transform: translateY(-10px);
  }
`;

const Arrow = styled(KeyboardArrowRight)`
  height:3rem;
  position:absolute;
  right:0;
  margin-top:-3.4rem;
  transition: all 0.4s ease;

  ${p => p.isActive ? 'transform: rotateZ(90deg);' : null };
`;

const Number = styled.span`
  color:black;
  margin-right:1rem;
  ${media.lessThan("tablet")`
    display:none;
  `}
`;

const Headline = styled(ThemeHeadline)`
  color: ${p => p.theme.darkGray};
  text-transform:uppercase;
  font-weight:bold;
`;

class Faq extends Component {

  state = {
    opened: [],
  }

  open = (i) => {
    let openedArr = this.state.opened
    let index = openedArr.indexOf(i)

    if (index === -1) {
      openedArr.push(i)
    } else {
      openedArr.splice(index, 1);
    }

    this.setState({opened: openedArr})
  }

  render() {
    const { faqs } = this.props

    return (
      <Fragment>
        <Items>
          {(faqs||[]).map((faq, i) => {
            let answerText = faq.answer.childMarkdownRemark.html

            return (
              <ItemsWrapper key={`item_${i}`}>
                <Question onClick={() => this.open(i)}>
                  <Headline h5>
                    <Number>{i + 1}.</Number> {faq.question}
                  </Headline>
                  <Arrow isActive={this.state.opened.includes(i)} />
                </Question>
                <Answer isActive={this.state.opened.includes(i)}>
                  <RichText body={answerText}  />
                </Answer>
              </ItemsWrapper>
            )
          })}
        </Items>
      </Fragment>
    )
  }
}

export default Faq;
