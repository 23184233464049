import React, { useState } from "react";
import { graphql } from "gatsby"
import Header from 'components/Header';
import Footer from 'components/Footer';
import Layout from 'components/Layouts/Default';
import ThemeHeadline from 'components/Theme/Headline';
import ThemeButton from 'components/Theme/Button';
import Meta from 'components/Meta';
import { variablesToObject } from 'lib/variablesToProps';
import { styled } from 'components/Theme/Styles';
import FormField from 'components/Forms//FormField';
import ServiceLayout from 'components/Layouts/Service';

const StyledContainer = styled.div`
  background-color: #fff;
  padding: 10rem 2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align:center;
`;

const SearchBox = styled.div`
  width:80rem;
`

const SerialLookupHeader = styled(ThemeHeadline)`
  font-size:2.6rem;
`;


const SerialLookupPage = (props) => {
  const [serialNumber, setSerialNumber] = useState("");

  const handleSerialNumberChange = (event) => {
    setSerialNumber(event.target.value);
  };


  const {
    title,
    description,
    pageImage,
    variables,
    meta } = props.data.contentfulPage;
    
  let descriptionText = description && description.description;
  let pageVariables = variablesToObject(variables, 'page').page;
  let headerProps = pageVariables && pageVariables.header;

  return (
    <Layout>
      <Meta title={title}
            description={descriptionText}
            searchEngineFeatures={meta.searchEngineFeatures}
            robotsAdvance={meta.robotsAdvance}
            canonicalUrl={meta.canonicalUrl}
            pageImage={pageImage}
            variables={meta.variables}
            schemaWebpage
            schemaOrganization
            openGraph
            twitter />

      <Header {...headerProps} />

      <ServiceLayout selected="Serial Number Lookup" heroTitle="Serial Number Lookup">
        <StyledContainer>
          <SearchBox>
            <SerialLookupHeader h2>
              View your Proluxe product's parts, warranty, and more.
            </SerialLookupHeader>

            <FormField 
              label="Enter Serial Number" 
              type="text" 
              change={handleSerialNumberChange}
            />
            
            <ThemeButton to={`/pages/serial-number-lookup/show?serial=${serialNumber}`}>SEARCH</ThemeButton>
          
          </SearchBox>
        </StyledContainer>
      </ServiceLayout>
      <Footer />
      
    </Layout>
  );
};

export default SerialLookupPage;

// language=GraphQL
export const pageQuery = graphql`
  query SerialNumberLookupQuery {
    contentfulPage(handle: { eq: "serial-number-lookup" }) {
      title
      description {
        description
      }
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }
      showSocialButtons
      blocks {
        ...Blocks
      }
      variables {
        key
        value
      }
    }
  }
`;