import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Layout from 'components/Layouts/Default';
import ThemeBlock, { Section } from 'components/Theme/Block'
import ThemeHeadline from 'components/Theme/Headline';
import Button from 'components/Theme/Button';
import { styled, media } from 'components/Theme/Styles';
import Link from 'components/Theme/Link';
import { GatsbyImage } from "gatsby-plugin-image"
import Moment from 'react-moment';


const Posts = styled.div`
  display:flex;
  flex-direction: row;
  width:70%;
  flex-wrap: wrap;
  margin-top:4rem;
  
  ${media.lessThan("tablet")`
    flex-flow:column;
    width:100%;
  `}
`;

const Post = styled.div`
  display:flex;
  flex-direction: row;
  
  margin:4rem;
  text-align:left;
  width:calc(50% - 8rem);

  ${media.lessThan("tablet")`
    width:calc(100% - 8rem);
    flex-direction:column;
  `}
`

const Description = styled.div`
  font-size:${p => p.theme.h5FontSize}rem;
  margin:1rem 0;

  ${media.lessThan("tablet")`
    font-size:${p => p.theme.h4FontSize}rem;
  `}
`

const Headline = styled(ThemeHeadline)`
  margin-top:2rem;
  line-height:3rem;

`

const HeroHeadline = styled(ThemeHeadline)`
${media.lessThan("tablet")`
  font-size:${p => p.theme.h2FontSize}rem;
`}
`

const Date = styled.div`
  font-size:${p => p.theme.h5FontSize}rem;
  color:${p => p.theme.mediumGrey};

  ${media.lessThan("tablet")`
    font-size:${p => p.theme.h4FontSize}rem;
    margin-top:2rem;
  `}
`

const PostDetails = styled.div`
  width:50%;
  padding-left:4rem;
  ${media.lessThan("tablet")`
    width:100%;
    padding-left:0;
  `}
`;

const PostImageWrapper = styled.div`
  width:50%;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const PostImage = styled(GatsbyImage)`
  object-fit:cover;
`;

const ReadMoreLink = styled(Link)`
  font-size:${p => p.theme.h5FontSize}rem;
  color:${p => p.theme.brandPrimary};
`;

const BlogFooter = styled(ThemeBlock)`
  margin-bottom:10rem;
`

const PrevButton = (props) => (
 <Button to={props.to} arrow={false} small={true} transparent={false} invert={false}>Prev</Button>
)

const NextButton = (props) => (
  <Button to={props.to} arrow={false} small={true} transparent={false} invert={false}>Next</Button>
)



class Blog extends Component {

  hasNextButton = () => {
    let ctx = this.props.pageContext
    return (ctx.currentPage < ctx.numPages)
  }

  hasPrevButton = () => {
    let ctx = this.props.pageContext
    return (ctx.currentPage !== 1)
  }

  prevPage = () => {
    let ctx = this.props.pageContext
    let prevPage = ctx.currentPage - 1;
    return (prevPage === 1) ? '/blog' : `/blog/${prevPage}`
  }

  render() {
    let posts = this.props.data.allContentfulPage.edges
    let ctx = this.props.pageContext

    return (
      <Layout>
        <Header floating={true} />

        <ThemeBlock height="Medium">
          <Section innerWidth="100%" align="Center" backgroundColor="#363636">
            <HeroHeadline h2 color="white">News</HeroHeadline>
          </Section>
        </ThemeBlock>

        <ThemeBlock height="Large">
          <Section innerWidth="100%" align="Center">
            <Posts>
            {posts.map((edge) => {
              let post = edge.node

              return (
                <Post key={`post_${post.id}`}>
                  <PostImageWrapper>
                    <Link to={`/pages/${post.handle}`}>
                      <PostImage alt={post.title} image={post.pageImage && post.pageImage.gatsbyImageData} />
                    </Link>
                  </PostImageWrapper>
                  <PostDetails>
                  <Date>
                      Published <Moment format="MMMM DD, YYYY">{post.publishedAt}</Moment>
                    </Date>
                    <Headline h3><Link to={`/pages/${post.handle}`}>{post.title}</Link></Headline>
                    <Description>{post.description.description}</Description>
                    <ReadMoreLink to={`/pages/${post.handle}`}>Read more</ReadMoreLink>
                  </PostDetails>
                </Post>
              )
            })}
            </Posts>
          </Section>
        </ThemeBlock>

        <BlogFooter>
          <Section align="Right" style={{padding:'5px'}}>
            {this.hasPrevButton() &&
              <PrevButton to={this.prevPage()} />
            }
          </Section>
          <Section align="Left" style={{padding:'5px'}}>
            { this.hasNextButton() &&
              <NextButton to={`/blog/${ctx.currentPage + 1}`}/>
            }
          </Section>
        </BlogFooter>


        <Footer />
      </Layout>
    );
  }
}

export default Blog;

// language=GraphQL
export const blogQuery = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    allContentfulPage(
      filter: {kind: { eq: "Blog" } }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          handle
          publishedAt
          description {
            description
          }
          pageImage {
            gatsbyImageData
          }
          meta {
            ...Meta
          }
          showSocialButtons
          blocks {
            ...Blocks
          }
          variables {
            key
            value
          }
        }
      }
    }
  }
`;


