import React, { Component } from 'react';
import SubNav, { SubNavGroup, SubNavItem } from './SubNav';
import { styled, css } from 'components/Theme/Styles';

const SuperGroup = styled.div``;

const SuperGroupHeadline = styled.div`
  padding-bottom:2rem;
  width:calc(100% - 1rem);
  margin-bottom:1.5rem;
`;

const GroupWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  white-space: nowrap;
  width:${p => p.width}vw;
`;


const Headline = styled.h3`
  font-size:${p => p.theme.h4FontSize}rem;
  font-weight:300;
  margin:0 0 0 0;
`;


const Item = styled(SubNavItem)`
  justify-content: center;
  min-height:15rem;
  min-width:15rem;
  overflow:hidden;
  & > a {
    display:block;
    width:100%;
    margin-right:1rem;
  }
`;

const Option = styled.div`
  width:100%;
  height:15rem;
  border:0.1rem solid ${p => p.theme.grey};
  border-radius: 0.2rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display:flex;
  cursor:pointer;
  transition: all 0.5s;
  font-size:${p => p.theme.h4FontSize}rem;

  &:hover {
    background-color:${p => p.theme.lightGrey};
  }
`;

class SupportMenu extends Component {
  render() {
    return (
      <SubNav text="Service" {...this.props}>
        <SuperGroup>
          <SuperGroupHeadline>
            <Headline>Get service, parts, and more - (800) 624-6717</Headline>
          </SuperGroupHeadline>
          <GroupWrapper>
            <SubNavGroup totalItems={4}>
              <Item to="/pages/maintenance">
                <Option>
                  Maintenance
                </Option>
              </Item>
            </SubNavGroup>
            
            <SubNavGroup totalItems={4}>
              <Item to="/pages/find-service">
                <Option>
                  Find Service Techs
                </Option>
              </Item>
            </SubNavGroup>
            <SubNavGroup totalItems={4}>
              <Item to="/pages/parts">
                <Option>
                  Parts
                </Option>
              </Item>
            </SubNavGroup>
            <SubNavGroup totalItems={4}>
              <Item to="/pages/contact-us">
                <Option>
                  Contact us
                </Option>
              </Item>
            </SubNavGroup>
          </GroupWrapper>
        </SuperGroup>
      </SubNav>
    );
  }
}

export default SupportMenu;
