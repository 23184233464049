import React, { Component } from 'react';
import { styled } from 'components/Theme/Styles';
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'components/Theme/Link';
import { AnchorLink } from "gatsby-plugin-anchor-links";


const Wrapper = styled.ul`
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width:${p => p.width}vw;
`;

const NavGroup = styled.li`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: space-evenly;  
  position: relative;
  text-align: left;
  align-items: center;
`;

const Items = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;

const Title = styled.div`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  padding: 1.2rem 0;
  width:90%;
  color:#000;
  text-align:center;
`;

const Image = styled(GatsbyImage)`
  margin-bottom:1rem;
  width:150px !important;
  height:150px !important;
  pointer-events: none
`

class SubNavGroup extends Component {
  render() {
    const { children, title, image, to, totalLists } = this.props;
    return (
      <Wrapper width={ 100 / (totalLists || 1) }>
        <NavGroup>
          <Items>
            { image &&
              <AnchorLink to={to} stripHash>
                <Image alt={title} image={image.gatsbyImageData}  />
              </AnchorLink>
            }
            { title != null ? <Title><AnchorLink to={to} stripHash>{ title }</AnchorLink></Title> : '' }
            { children }
          </Items>
        </NavGroup>
      </Wrapper>
    );
  }
}

export default SubNavGroup;
