import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby'
import ThemeButton from 'components/Theme/Button';
import { styled } from 'components/Theme/Styles';
import variablesToProps from 'lib/variablesToProps';

const Button = styled(ThemeButton)`
  text-align: center;
  display:block;
  margin-top: ${p => p.theme.spacing(p.marginTop)}rem;
`;

class Cta extends Component {
  render() {
    const { label, marginTop, size, cta, link } = this.props
    let buttonVars = cta && cta.button
    let appearanceVars = cta && cta.appearance

    return (
      <Fragment>
        <Button to={ link ? link : "#" } marginTop={marginTop} small={size === 'Small'} {...buttonVars} {...appearanceVars}>{label}</Button>
      </Fragment>
    )
  }
}

export default variablesToProps('cta')(Cta);

// language=GraphQL
export const ctaFragment = graphql`
  fragment Cta on ContentfulSectionCta {
    label
    marginTop
    link
    size
    variables {
      key
      value
    }
  }
`;