import React, { Component } from 'react';
import { styled, css, media } from 'components/Theme/Styles';
import _ from 'lodash';
import { KeyboardArrowDown } from 'styled-icons/material'
import { keyframes } from './Styles'

const minHeightMixin = css`
  ${ props => {
  switch (props.height) {
    case 'XL':
      return '100vh'
    case 'Large':
      return '80vh'
    case 'Medium':
      return '50vh'
    case 'Small':
      return '30vh'
    case 'XS':
      return '15vh'
    default:
      return 'auto';
  }}}
`;

const BlockWrapper = styled.div`
  display: flex;
  flex-flow: row;
  position:relative;
  height: auto;
  width:100%;
  justify-content: space-around;
  align-items: stretch;
  box-sizing:border-box;
  background-color: ${ p => p.color };
  min-height: ${minHeightMixin};
  
  
  // finishing
  ${p => (p.finishing && p.finishing === 'Frame') ? 'border:2.5rem solid #FFFFFF' : null};
    
  ${media.lessThan("tablet")`
    flex-flow:${p => (p.direction ? 'column-reverse' : 'column')};
  `}
`;

const Bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(0.7rem);
  }
  60% {
    transform: translateY(0.9rem);
  }
`;

const Arrow = styled(KeyboardArrowDown)`  
  margin:0;
  fill: ${p => p.theme.black};
  height: 3rem;
  animation: ${Bounce} 2s infinite;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  bottom: -3rem;
  margin:0 auto;
  border-radius:8rem;
  background-color:white;
  z-index:123;
  padding:1rem;
  box-shadow:0 0.1rem 0.5rem #999;
`;


class Block extends Component {
  getJustifyFromChild() {
    return this.props.children && this.props.children.props && this.props.children.props.justify ?
      this.props.children.props.justify : null
  }

  render() {
    const attrs = _.omit(this.props, ['className'])

    return (
      <BlockWrapper justify={this.getJustifyFromChild()} className={this.props.className} {...attrs}>
        {this.props.children}
        { this.props.finishing && this.props.finishing === 'Scroll Arrow' &&
          <ArrowWrapper height={this.props.height}><Arrow /></ArrowWrapper>
        }
      </BlockWrapper>
    )
  }
}

export Section from './Block/Section'

export default Block;
