import React, { Component, Fragment } from 'react';
import { styled } from 'components/Theme/Styles';

const Select = styled.select`
  width:${p => p.theme.formInputWidth}rem;
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.formInputFontSize}rem;
  box-sizing: border-box;
  background-color:transparent;
  border:0;
  text-indent: 0;
  padding:0;
  color: inherit;
  appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, ${p => p.theme.mediumGrey} 50%),
    linear-gradient(135deg, ${p => p.theme.mediumGrey} 50%, transparent 50%),
    linear-gradient(to right, ${p => p.theme.mediumGrey}, ${p => p.theme.mediumGrey});
  background-position:
    calc(100% - 3.5rem) 1rem,
    calc(100% - 3rem) 1rem,
    calc(100% - 5rem) 0.5rem;
  background-size:
    0.5rem 0.5rem,
    0.5rem 0.5rem,
    0.1rem 1.5em;
  background-repeat: no-repeat;
  &:focus {
    outline:none;
    background-color:#f8f8f8;
  }
  
  &:invalid {
    color:${p => p.theme.formPlaceholderColor};
  }
`;


class SelectBox extends Component {

  render() {
    const { name, label, value, required, options } = this.props


    return (
      <Fragment>
        <Select name={name} placeholder={label} value={value} required={required}>
          <option hidden value="">{label}</option>
          {(options||[]).map((option, i) => {
            return (
              <option key={`select_${i}`} value={option}>{option}</option>
            )
          })}
        </Select>

      </Fragment>
    )
  }
}

export default SelectBox;
