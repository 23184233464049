import React, { Component } from 'react';
import { styled } from 'components/Theme/Styles';

const vAlign = 0.4;
const size = 4.8;

const CheckboxContainer = styled.div` 
  
`

const CheckboxLabel = styled.span`
  &:before {
    content: "";
    display: inline-block;
    margin-right: 1.5rem;
    border: solid 0.2rem rgba(0, 0, 0, 0.42);
    border-radius: 0.2rem;
    width: 1.4rem;
    height: 1.4rem;
    vertical-align: -${vAlign}rem;
    transition: border-color 0.5s, background-color 0.5s;
  }
  
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0.8rem;
    left: 0;
    width: 0.5rem;
    height: 1rem;
    border: solid 0.2rem transparent;
    border-left: none;
    border-top: none;
    transform: translate(0.55rem, 0.1rem) rotate(45deg);
  }
`;

const CheckboxInput = styled.input`
  appearance: none;
  position: absolute;
  z-index: -1;
  left: -1.5rem;
  top: -1.5rem;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: ${size}rem;
  height: ${size}rem;
  background-color: rgba(0, 0, 0, 0.42);
  outline: none;
  opacity: 0;
  transform: scale(1);
  transition: opacity 0.5s, transform 0.5s;
  
  &:checked {
    background-color: ${p => p.theme.brandPrimary};
  }
  
  &:disabled {
    opacity: 0;
  }
  
  &:disabled + ${CheckboxLabel} {
    cursor: initial;
  }
  
  &:checked + ${CheckboxLabel}::before {
    border-color: ${p => p.theme.brandPrimary};
    background-color: ${p => p.theme.brandPrimary};
  }
  
  &:active + ${CheckboxLabel}::before {
    border-color: ${p => p.theme.brandPrimary};
  }
  
  &:checked:active + ${CheckboxLabel}::before {
    border-color: #000;
    background-color: rgba(0, 0, 0, 0.42);
  }
  
  &:disabled + ${CheckboxLabel}::before {
    border-color: rgba(0, 0, 0, 0.26);
  }
  
  &:checked:disabled + ${CheckboxLabel}::before {
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.26);
  }
  
  &:checked + ${CheckboxLabel}::after {
    border-color: #fff;
  }
`;

const CheckboxWrapper = styled.div`
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size:${p => p.theme.formInputFontSize}rem;
  line-height: 3rem;
  &:active > input {
    opacity: 1;
    transform: scale(0);
    transition: opacity 0s, transform 0s;
  }
`;

const Label = styled.div`
  font-size:${p => p.theme.formInputFontSize}rem;
  color:${p => p.theme.formPlaceholderColor};
  margin-bottom:1.5rem;
`;


class Checkbox extends Component {
  state = {
    checked: false
  }

  onClick = (e) => {
    this.setState({checked: !this.state.checked})
  }

  render() {
    const { name, option } = this.props;

    return (
      <CheckboxWrapper onClick={this.onClick}>
        <CheckboxInput type="checkbox" name={name} value={option} checked={this.state.checked}  /> <CheckboxLabel>{option}</CheckboxLabel>
      </CheckboxWrapper>
    )
  }
}

class Checkboxes extends Component {
  render() {
    const { label, name, options } = this.props;

    return (
      <CheckboxContainer>
        <Label>{label}</Label>
        {( options || [] ).map((option, i) =>
          <Checkbox key={`option_${i}`} name={name} option={option} />
        )}
      </CheckboxContainer>
    )
  }
}

export default Checkboxes;
