import React, { Component } from 'react';
import Config from 'config'
import { graphql, StaticQuery } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import theme from 'components/Theme/Globals'
import 'components/Theme/Fonts.css'
import 'components/Theme/Globals';
import { currentHref } from 'lib/currentPath';

const ShopifyBuy = typeof window !== `undefined` ? require('@shopify/buy-button-js') : null

class Layout extends Component {
  constructor(props) {
    super(props);

    // Set defaults for meta data (OpenGraph, Twitter, etc)
    Config.set(Object.assign({}, {
      currentUrl: currentHref(),
    }, Config.defaults));

    if (typeof window !== `undefined`) {
      
  
      window.client = ShopifyBuy.buildClient({
        domain: 'shop.proluxe.com',
        storefrontAccessToken: 'c7bc31fe7bf682441b92846e64b0a9b0', // previously apiKey, now deprecated
      });
  
      window.shopifyUI = ShopifyBuy.UI.init(window.client);
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>  
          <div id="main">
            { this.props.children }
          </div>
      </ThemeProvider>
    )
  }
}

export default Layout
