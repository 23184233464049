import React, { Component } from 'react';
import { styled } from 'components/Theme/Styles';
import { Portal } from 'react-portal';

const Wrapper = styled.div`
  position: relative;
  white-space: nowrap;
  display:flex;
  justify-content: space-evenly;
`;

export const LinkWrapper = styled.div`
  cursor:pointer;
  position:relative;
  top:2px;
  border-bottom:2px solid ${p => (p.selected && p.theme.brandPrimary) || 'transparent'};
  padding:1rem;
  font-weight: bold;
  font-size:1.4rem;
`;

const Menu = styled.div`
  width: 100%;
  display:${p => p.open ? 'block' : 'none'};
  border-top:1px solid ${p => p.theme.lightGrey};
  box-sizing: border-box;
  background-color:#fff;
`;

const MenuItemsWrapper = styled.ul`
  width: 100%;
  
  padding: 3rem 3rem 5rem 3rem;
`;

const MenuItems = styled.li`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width:100%;
`;

class SubNav extends Component {
  state = {
    open: false
  };

  onOpen = () => {
    this.props.onMenuOpen(this.props.activeKey)
  };


  render() {
    const { text, children, inverse, padding, activeKey, activeMenu, menuOpen } = this.props;
    const { open } = this.state
    

    return (
      <Wrapper>
        <LinkWrapper selected={activeKey === activeMenu}
                     inverse={inverse}
                     onClick={ this.onOpen }
                     open={menuOpen}>
          {text}
        </LinkWrapper>


        <Portal node={typeof document !== 'undefined' ? document.querySelector('#desktopHeader') : null}>
          <Menu open={activeKey === activeMenu}>
            <MenuItemsWrapper padding={padding}>
              <MenuItems>
                { children }
              </MenuItems>
            </MenuItemsWrapper>
          </Menu>
        </Portal>

      </Wrapper>
    );
  }
}

export SubNavGroup from './SubNav/SubNavGroup';
export SubNavItem from './SubNav/SubNavItem';
export default SubNav;

