import React, { Component } from 'react';
import processHtml from 'utils/processHTML'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components';

const Content = styled.div`
  //width: 90rem;
  //margin: 10rem auto;
  
  font-size: 1.728rem;
  
  h2 {
    font-family: ${p => p.theme.fontFamilyTitle};
    font-size: ${p => p.theme.h2FontSize}rem;
    
    margin:5rem 0 2rem 0;
    font-weight: 300;
    font-style: normal;
  }
  
  h3 {
    letter-spacing:${p => p.theme.h3FontSize * .03}rem;
    font-size: ${p => p.theme.h3FontSize}rem;
    line-height:${p => p.theme.h3FontSize * 1.5}rem;
    color:#000000;
    margin:5rem 0 2rem 0;
    font-weight: 300;
    font-style: normal;
  }
  
  h4 {
    margin-top: 3rem;
    margin-left: -0.15rem;
    font-weight: 300;
    letter-spacing:${p => p.theme.h4FontSize * .03}rem;
    font-size: ${p => p.theme.h4FontSize}rem;
    line-height:${p => p.theme.h4FontSize * 1.5}rem;
  }
  
  h5 {
    font-weight: 300;
    letter-spacing:${p => p.theme.h5FontSize * .03}rem;
    font-size: ${p => p.theme.h5FontSize}rem;
    line-height:${p => p.theme.h5FontSize * 1.5}rem;
  }
  
  div,  p,  ul,  ol,  a {
    font-family: ${p => p.theme.fontFamily};
    line-height:2.5rem;
    margin-bottom:0;
  }
  
  a,  a:hover {
    text-decoration: none;
    color: ${p => p.theme.brandPrimary};
    border-bottom: 0.1rem solid;
  }
  
  img {
    max-width: 100%;
  }
  
  * > ul, * > ol,  > ul,  > ol {
    padding-top: 1.5rem;
  }
  
  * > ul li, * > ol li,  > ul li,  > ol li {
    text-indent: -0.9rem;
    margin-left: 3rem;
    position: relative;
    margin-bottom: 0;
    
  }
  
  * > ul li:before, * > ol li:before,  > ul li:before,  > ol li:before {
    content: " ";
    font-weight: bold;
    display: inline-block;
    position:relative;
    left:-1rem;
    padding-right:0.7rem;
  }
  
  * > ul li p, * > ol li p,  > ul li p,  > ol li p {
    display:inline;
    text-indent:0;
  }
  
  *:not(li) > ul, *:not(li) > ol, :not(li) > ul, :not(li) > ol {
    margin: 0;
    list-style: none;
    padding: 3rem;
    border-radius: 0.5rem;
    display: block;
    width: fit-content;
  }
  
  *:not(li) > ul li, *:not(li) > ol li, :not(li) > ul li, :not(li) > ol li {
    margin-bottom: 1.4rem;
  }
  
  *:not(li) > ol, :not(li) > ol {
    counter-reset: section;
  }
  
  *:not(li) > ol > li:before, :not(li) > ol > li:before {
    counter-increment: section;
    content: counters(section, ".") ".";
  }
  
  *:not(li) > ul > li:before, :not(li) > ul > li:before {
    font-size: 1.6rem;
    position:relative;
    right:0.5rem;
    top:-0.1rem;
    content: "•";
  }

  p {
    margin-top:0 !important;
  }
  
  p.small, div.small {
    font-size: 1.6rem;
  }
  
  div, div:focus, p, p:focus {
    margin-top: 2.9rem;
    word-break: break-word;
    word-wrap: break-word;
  }
  
  h1 + p,
  h2 + p,
  h3 + p,
  h1 + div,
  h2 + div,
  h3 + div {
    margin-top: 1rem;
  }
  
  h4 + p,
  h4 + div {
    margin-top: 0.6rem;
  }
  
  blockquote {
    border: none;
    padding: 2rem 7rem;
    position: relative;
    z-index: 1;
    margin: 0 0 2rem;
    font-size: 1.75rem;
  }
  
  blockquote div {
    font-style: italic;
  }
  
  blockquote:before {
    background: transparent url(/static/images/quotation-marks-left.png) no-repeat 0 0;
    content: "";
    position: absolute;
    width: 7.1rem;
    height: 7.1rem;
    background-size: 100% auto;
    top: 1rem;
    left: 1rem;
    z-index: -1;
  }
  
  blockquote:after {
    background: transparent url(/static/images/quotation-marks-right.png) no-repeat 0 0;
    content: "";
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100% auto;
    position: absolute;
    width: 7.1rem;
    height: 7.1rem;
    left: auto;
    top: auto;
    bottom: 1rem;
    right: 1rem;
    z-index: -1;
  }
  
  form {
    width: 50%;
    margin: 0 auto;
  }
`

class RichText extends Component {
  render() {
    const { className, body } = this.props

    return (
      <Content className={className}>
        { ReactHtmlParser((body || this.props.children), { transform: processHtml }) }
      </Content>
    );
  }
}


export default RichText;
