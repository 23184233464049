import React, { Component } from 'react';
import Helmet from 'react-helmet';
import variablesToProps from 'lib/variablesToProps';

class SchemaOrganization extends Component {

  getContactPoints() {
    const contacts = [];
    (this.props.contacts || []).forEach(contact => {
      contacts.push({
        "@type": "ContactPoint",
        "telephone": `${contact.phone}`,
        "contactType": `${contact.type}`,
        "contactOption": "TollFree",
        "areaServed": `${contact.areaServed}`
      });
    });

    return contacts;
  }

  render() {
    const {
      title,
      description,
      pageImage,
      schemaOrganization
    } = this.props

    const {
      url,
      email,
      foundingDate,
      street,
      city,
      state,
      country,
      zip,
      sameAs
    } = schemaOrganization

    let logo = pageImage && pageImage.file && pageImage.file.url;

    let schema = {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Organization",
        "@id": "#organization",
        "name": `${title}`,
        "url": `${url}`,
        "logo": `${logo}`,
        "email": `${email}`,
        "description": `${description}`,
        "foundingDate": `${foundingDate}`,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": `${street}`,
          "addressLocality": `${city}`,
          "addressRegion": `${state}`,
          "addressCountry": `${country}`,
          "postalCode": `${zip}`
        },
        "sameAs": `${sameAs}`,
        "contactPoint": this.getContactPoints()
      })
    };

    return (
      <Helmet script={[schema]} />
    )

  }
}

export default variablesToProps('schemaOrganization')(SchemaOrganization);
