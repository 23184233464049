import React, { Component } from 'react';
import { graphql } from 'gatsby'
import ThemeBlock from 'components/Theme/Block';
import Section from './Section';

class Block extends Component {

  render() {
    const { height, finishing, sections, direction } = this.props;

    return (
      <ThemeBlock height={height} finishing={finishing} direction={direction}>
        {(sections || []).map((section, i) => {
          return <Section  key={`section_${i}`} {...section} />
        }) }
      </ThemeBlock>
    )
  }
}

export default Block;

export const query = graphql`
  fragment Blocks on ContentfulComponentBlock {
    height
    finishing
    direction
    sections {
      ...Section
    }
    variables {
      key
      value
    }
  }
`;

