import { styled, media } from 'components/Theme/Styles';

const TextBox = styled.input`
  width:100%;
  font-size:${p => p.textSmall ? p.theme.formInputFontSize / 1.2 : p.theme.formInputFontSize}rem;
  border:0;
  background: ${p => p.theme.white};
  text-align: ${p => p.textAlign ? p.textAlign : 'left'};
  &::placeholder {
    text-align: ${p => p.textAlign ? p.textAlign : 'left'};
    color:${p => p.theme.formPlaceholderColor};
  }
  
  ${media.lessThan("tablet")`
    font-size:${p => p.theme.formInputFontSize}rem;
  `}
`;

export default TextBox;