import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Block from 'components/Block';
import Footer from 'components/Footer';
import Meta from 'components/Meta';
import { variablesToObject } from 'lib/variablesToProps';
import Layout from 'components/Layouts/Default';
import { styled, media } from 'components/Theme/Styles';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton, } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, EmailIcon } from 'react-share';
import { currentHref } from 'lib/currentPath';

const SocialIcons = styled.div`
  position:fixed;
  display:flex;
  bottom:40vh;
  z-index:200;
  flex-direction: column;
  & > .SocialMediaShareButton {
    cursor:pointer;
  }
  ${media.lessThan("tablet")`
    display:none;
  `}
`

const SocialSharing = (props) => (
  <SocialIcons>
    <FacebookShareButton url={props.url} quote={props.title}>
      <FacebookIcon size={48} />
    </FacebookShareButton>
    <TwitterShareButton url={props.url} title={props.title}>
      <TwitterIcon size={48} />
    </TwitterShareButton>
    <LinkedinShareButton url={props.url} title={props.title}>
      <LinkedinIcon size={48} />
    </LinkedinShareButton>
    <EmailShareButton url={props.url} subject={props.title} body={props.description}>
      <EmailIcon size={48} />
    </EmailShareButton>
  </SocialIcons>
)

class Page extends Component {
  render() {
    const {
      title,
      description,
      pageImage,
      blocks,
      showSocialButtons,
      variables,
      meta } = this.props.data.contentfulPage;

    let descriptionText = description && description.description;
    let pageVariables = variablesToObject(variables, 'page').page;
    let headerProps = pageVariables && pageVariables.header;
    let footerProps = pageVariables && pageVariables.footer;

    return (
      <Layout>
        <Meta title={title}
              description={descriptionText}
              searchEngineFeatures={meta.searchEngineFeatures}
              robotsAdvance={meta.robotsAdvance}
              canonicalUrl={meta.canonicalUrl}
              pageImage={pageImage}
              variables={meta.variables}
              schemaWebpage
              schemaOrganization
              openGraph
              twitter />

        <Header {...headerProps} />

        <div id="content">
        {(blocks || []).map((block, i) => {
          return <Block key={`block_${i}`} {...block} />
        }) }
        </div>

        {showSocialButtons &&
          <SocialSharing title={title} description={description} url={currentHref()} />
        }

        <Footer hideNewsletterSignup={footerProps && footerProps.hideNewsletterSignup} />
      </Layout>
    );
  }
}

export default Page;

// language=GraphQL
export const pageQuery = graphql`
  query PageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      description {
        description
      }
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }
      showSocialButtons
      blocks {
        ...Blocks
      }
      variables {
        key
        value
      }
    }
  }
`;


