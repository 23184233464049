import React, { Component } from 'react'
import { styled, media } from 'components/Theme/Styles';
import ThemeHeadline from 'components/Theme/Headline';
import ThemeButton from 'components/Theme/Button';
import { GatsbyImage } from "gatsby-plugin-image"

const Product = styled.div`
  display:block;
  margin:2rem;
  padding:1rem;
  ${media.lessThan("tablet")`
    margin-top:3rem;
  `}
`;

const Name = styled(ThemeHeadline)`
  font-weight:bold;
  ${media.lessThan("tablet")`
    font-size:${p => p.theme.h4FontSize}rem;
  `}
`;

const Description = styled(ThemeHeadline)`
  margin-bottom:1rem;
  
  ${media.lessThan("tablet")`
    font-size:${p => p.theme.h4FontSize}rem;
  `}
`;

const Image = styled(GatsbyImage)`
  max-width:20rem;
`;

class ProductCard extends Component {

  render() {
    const { product, to } = this.props
    let variant = product.variants[0]
    
    return (
      <Product to={to}>
        { variant.image &&
        <Image alt={product.title} image={variant.image.gatsbyImageData} />
        }

        <div>
          <Name h5>{product.title}</Name>
          <Description h5>{variant.sku}</Description>
          <ThemeButton to={to} invert={true}  small={true}>View Model</ThemeButton>
        </div>
      </Product>
    )
  }
}

export default ProductCard;
