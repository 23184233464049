import React, { Component } from 'react'
import { styled, media } from 'components/Theme/Styles';
import Globals from './Globals';

const Scroller = styled.div`
  height:auto;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space:nowrap;
  display: flex;
  width:calc(100vw - ${Globals.blockPaddingLarge * 2}rem);
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  flex-direction: row;
  border: 0.1rem solid ${p => p.theme.lightGrey};
  &::-webkit-scrollbar {
    display: none;
  }
  
  ${media.lessThan("tablet")`
    width:calc(100vw - ${Globals.blockPaddingSmall}rem);
  `}
`;

const ScrollerInner = styled.div`
  flex:0 0 auto;
  position:relative;
`;

class Overflow extends Component {

  render() {
    return (
      <Scroller>
        <ScrollerInner>
          { this.props.children }
        </ScrollerInner>
      </Scroller>
    )
  }
}

export default Overflow;