import React, { Component, Fragment } from 'react'
import { styled } from 'components/Theme/Styles';

const TextArea = styled.textarea`
  width:100%;
  height:${p => p.theme.formInputWidth / 4}rem;
  font-size:${p => p.theme.formInputFontSize}rem;
  display:block;
  border:0;
  resize: vertical;
  &:focus {
    outline: none;
  }
`;

class TextAreaField extends Component {
  render() {
    const { name, label, value, required } = this.props

    return (
      <Fragment>
        <TextArea name={name} required={required} value={value} placeholder={label} />
      </Fragment>
    )
  }
}

export default TextAreaField;
