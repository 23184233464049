import React, { Component } from 'react';
import { styled, media } from 'components/Theme/Styles';
import FormField from './FormField';
import { ThemeButton } from '../Theme/Button';
import { graphql } from 'gatsby'
import variablesToProps from 'lib/variablesToProps';
import qs from 'qs';

const Wrapper = styled.div`
  margin-bottom:${p => p.direction === 'Vertical' ? '10rem' : ''};
  width:100%;
`;

const ButtonWrapper = styled.div`
  display:flex;
  justify-content: center;
`

const Button = styled(ThemeButton)`
  margin-top: 2rem;
`;

const RichForm = styled.div`
  display:flex;
  flex-direction: ${p => p.direction === 'Vertical' && !p.hasDoubleVerticalColumns ? 'column' : 'row'};;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  margin-top:2rem;
  
  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`;

class Form extends Component {
  state = {
    success: false,
    error: null,
    errorMsg: null
  }

  componentWillMount() {
    if (typeof window === 'object') {
      this.params = qs.parse(window.location.search.replace('?', ''))
    }
  }

  gaClientID() {
    let ga;

    try {
      let tracker = ga.getAll()[0];
      return tracker.get('clientId');
    } catch(e) {}
  }

  render() {
    const { name, submitText, successPage, contentfulfields, direction, doubleVerticalColumns, form } = this.props;

    let fields = contentfulfields
    let buttonVars = form && form.cta && form.cta.button
    let hasDoubleVerticalColumns = (direction === 'Vertical' && doubleVerticalColumns)
    let clientID = this.gaClientID()

    return (
      <Wrapper direction={direction}>
        <form data-netlify="true"
              name={name}
              method="POST"
              netlify-honeypot="fillmeout"
              action={successPage && (`/pages/` + successPage.slug)} netlify>

            <input type="hidden" aria-label="form" name="form-name" value={name} />
            <input type="hidden" aria-label="utm_source" data-tracking-name="utm_source" name="utm_source" />
            <input type="hidden" aria-label="utm_campaign" data-tracking-name="utm_campaign" name="utm_campaign" />
            <input type="hidden" aria-label="utm_term" data-tracking-name="utm_term" name="utm_term" />
            <input type="hidden" aria-label="utm_medium" data-tracking-name="utm_medium" name="utm_medium" />
            <input type="hidden" aria-label="utm_content" data-tracking-name="utm_content" name="utm_content" />
            <input type="hidden" aria-label="client_id" data-tracking-name="client_id" name="client_id" value={clientID} />
            <div hidden><input aria-label="fillmeout" name="fillmeout"/></div>

            <RichForm direction={direction} hasDoubleVerticalColumns={hasDoubleVerticalColumns}>
              { this.props.children }

              {(fields || []).map((field, i) =>
                <FormField key={`field_${i}`}
                           {...field}
                           direction={direction}
                           hasDoubleVerticalColumns={hasDoubleVerticalColumns}
                           value={this.params && field ? this.params[field.name] : ''} />
              )}

            </RichForm>

            <ButtonWrapper>
              <Button type="submit" {...buttonVars}>{ submitText || `Submit`}</Button>
            </ButtonWrapper>
          </form>
      </Wrapper>

    )
  }
}

export default variablesToProps('form')(Form);

// language=GraphQL
export const formFragment = graphql`
  fragment Form on ContentfulSectionForm {
    __typename
    name
    direction
    doubleVerticalColumns
    submitText
    successPage {
      slug
    }
    contentfulfields {
      ...Field
    }
    variables {
      key
      value
    }
  }
`;


