import React, { Component } from 'react';
import Block from 'components/Block';


class Features extends Component {

  render() {
    const { blocks } = this.props

    return (
      <div id="features">
        {(blocks || []).map((block, i) => {
          return <Block key={`block_${i}`} {...block} data-aos="fade-in" />
        }) }
      </div>
    )
  }
}

export default Features;
