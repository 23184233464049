import axios from "axios";
import React, { useEffect, useState } from "react";
import { navigate, graphql } from "gatsby"
import Header from 'components/Header';
import Footer from 'components/Footer';
import Layout from 'components/Layouts/Default';
import ThemeHeadline from 'components/Theme/Headline';
import ThemeButton from 'components/Theme/Button';
import Meta from 'components/Meta';
import { variablesToObject } from 'lib/variablesToProps';

import { GatsbyImage } from "gatsby-plugin-image"
import { styled, media } from 'components/Theme/Styles';
import SpecTable from 'components/Product/SpecTable';
import Certs from 'components/Theme/Certs';


import ServiceLayout from 'components/Layouts/Service';


const LookupContainer = styled.div`
  padding:3rem;
`;

const Details = styled.div`
  display: flex;
  height:30rem;
  padding:1rem;
  justify-content:center;
  align-items:center;
`;

const ModelImage = styled.div`
  overflow:hidden;
`;

const Facts = styled.div`
  background-color:#f9f9f9;
  flex:1;
  height:100%;
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex-direction:row;
  padding-left:10rem;
  position:relative;
`;

const HelpfulResources = styled.div`
  
`;


const Headline = styled(ThemeHeadline)`
  color:#333;
  margin-bottom:0.3rem;
  span {
    text-transform: uppercase;
    color:#000;
  }
`;

const Deets = styled.div`
  display:flex;
  justify-content:center;
  
  flex-direction:column;
`;

const RightBox = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative;
`;

const NotFound = styled.div`
  width:100%;
  height:40vh;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
`;

const WarrantyChip = styled.div`
  margin:1rem 0;
  width:10rem;
  font-size:1.4rem;
  text-transform:uppercase;
  padding:1rem 2rem;
  text-align:center;
  color:#fff;
  top:0;
  right:0; 
  position:absolute;
  background-color:${p => p.expired ? p.theme.brandDanger : p.theme.brandSuccess};
`

const SerialLookupShow = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [found, setFound] = useState(false);
  const [data, setData] = useState({});
  const [model, setModel] = useState({});

  let serial = null;
  let products = props.data.allContentfulProduct;

  if (typeof window !== `undefined`) {
    const params = new URLSearchParams(window.location.search);
    serial = params.get("serial");
  }
  

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `https://api.proluxe.com/serial/${serial}`
      );

      setLoaded(true);
      setData(response.data);
      setFound(!response.data.name == "");
      setModel(products.nodes.find(product => product.modelNumber === response.data.model_number));

    };

    fetchData();
  }, [serial]);

  const Warranty = () => {
    let warranty_expire = new Date(data.warranty_expire);
    if (warranty_expire < new Date()) {
      return <WarrantyChip expired={true}>Expired</WarrantyChip>
    } else {
      return <WarrantyChip expired={false}>Active</WarrantyChip>
    }
  }

  const {
    title,
    description,
    pageImage,
    variables,
    meta } = props.data.contentfulPage;
    
  let descriptionText = description && description.description;
  let pageVariables = variablesToObject(variables, 'page').page;
  let headerProps = pageVariables && pageVariables.header;

  return (
    <Layout>
      <Meta title={title}
            description={descriptionText}
            searchEngineFeatures={meta.searchEngineFeatures}
            robotsAdvance={meta.robotsAdvance}
            canonicalUrl={meta.canonicalUrl}
            pageImage={pageImage}
            variables={meta.variables}
            schemaWebpage
            schemaOrganization
            openGraph
            twitter />

      <Header {...headerProps} />

      <ServiceLayout selected="Serial Number Lookup" heroTitle="Serial Number Lookup">
        { loaded && found && 
          <LookupContainer>
            <Details>

              <ModelImage>
              { model && model.images &&
                <GatsbyImage  image={model.images[0].gatsbyImageData} /> 
              }
              </ModelImage>

              <Facts>
                <Deets>
                  <Headline h3>{data.model_number}</Headline>
                  <Headline h4><span>Serial No.</span> {serial}</Headline>
                  
                  <Headline h4><span>Date Sold:</span> {data.date_sold}</Headline>
                  <Headline h4><span>Warranty Expiration Date:</span> {data.warranty_expire}</Headline>

                  <ThemeButton to={`/pages/get-service`}>Get Service</ThemeButton>
                </Deets>

                <RightBox>
                  <Certs certifications={model.certifications} />
                </RightBox>

                <Warranty />

              </Facts>
            </Details>

            <HelpfulResources>
              <SpecTable specs={model.specs} />
            </HelpfulResources>
            {/* {productName && <PartsList productName={productName} />} */}   
          </LookupContainer>
        }

        { loaded && !found && 
          <NotFound>
            <Headline h3>Could not find a product with that serial number.</Headline>
            <ThemeButton to="/pages/serial-number-lookup">Try again</ThemeButton>
          </NotFound>
        }
      </ServiceLayout>
      <Footer />
      
    </Layout>
  );
};



export default SerialLookupShow;

// language=GraphQL
export const pageQuery = graphql`
  query SerialNumberLookupShowQuery {
    contentfulPage(handle: { eq: "serial-number-lookup" }) {
      title
      description {
        description
      }
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }
      showSocialButtons
      blocks {
        ...Blocks
      }
      variables {
        key
        value
      }
    }

    allContentfulProduct {
      nodes {
        modelNumber
        specs {
          key
          value
        }
        certifications
        specSheet {
          file {
            url
            fileName
            contentType
          }
        }
        specAssets {
          gatsbyImageData(height: 300)
        }
        images {
          gatsbyImageData(width: 300)
        }
      }
    }
    
  }
`;