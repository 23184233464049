import React, { Component } from 'react'
import { styled } from 'components/Theme/Styles';
import DropDownMenu from './DesktopNav/DropDownMenu'
import ContactUs from './DesktopNav/ContactUs'
import Logo from 'components/Theme/Logo';
import Link from 'gatsby-link';

const TopWrapper = styled.div`
  
  ${p => !p.floating && `height:9rem;`};
`;

const Header = styled.div`
  position:absolute;
  top:0;
  left: 0;
  z-index: 100;
  width: calc(100% - 2rem);
  margin:1rem;
  
  ${p => !p.floating && `background-color:#fff`};
  
  ${p => p.menuOpen && `
    border-bottom:0.1rem solid ${p.theme.grey};
    box-shadow:0 0.3rem 15rem rgba(0,0,0,3);
  `}

  ${p => (p.menuOpen || p.scrolled) && `
    border-bottom:0.1rem solid ${p.theme.grey};
    box-shadow:0 0.3rem 15rem rgba(0,0,0,0.2);
    background-color:rgba(255,255,255,0.9);
    border-radius:0.8rem;
    backdrop-filter: blur(20px);
  `}

  color: ${p => p.menuOpen || p.scrolled || p.inverse ? p.theme.black : p.theme.white};
  position:fixed;
`;

const Spacer = styled.div`
  height:${p => p.theme.headerHeight}rem;
`;

const TopBar = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:7rem;
  
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:calc(100vw - ${p => p.theme.blockPaddingSmall * 2}rem);
  text-transform:uppercase;
  font-weight: 300;
`;

const NavBarCenter = styled.div`
color: ${p => p.menuOpen || p.scrolled || p.inverse ? p.theme.black : p.theme.white};
  font-size: ${p => p.theme.navItemFontSize}rem;
  padding: 0;

  font-style: normal;
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  width:33%;
  flex-basis: 33%;
  min-width: 60rem;
`;


const NavBarRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width:33%;
  flex-basis: 33%;
  text-transform:uppercase;
  font-size: ${p => p.theme.navItemFontSize}rem;

`;

const Proluxe = styled(Logo)`
  color: ${p => p.menuOpen || p.inverse ? p.theme.black : p.theme.white};
  flex-basis: 33%;
`;

const Wrapper = styled.div`
  position: relative;
  white-space: nowrap;
  display:flex;
  justify-content: space-evenly;
  a {
    color:${p => (p.inverse && p.theme.white) || p.theme.darkGray};
  }
`;

const DirectLink = styled(Link)`
  cursor:pointer;
  position:relative;
  top:2px;
  border-bottom:2px solid ${p => (p.selected && p.theme.brandPrimary) || 'transparent'};
  padding:1rem;
  font-weight: bold;
  color: ${p => p.menuOpen || p.scrolled || p.inverse ? p.theme.black : p.theme.white};
  font-size:1.4rem;
`;


class DesktopNav extends Component {
  state = {
    floating: this.props.floating,
    inverse: this.props.inverse,
    menuOpen: false,
    activeMenu: null,
    scrolled: false
  };

  openMenu = (activeKey) => {
    this.setState({ menuOpen: true, inverse: false, activeMenu: activeKey });
  };

  closeMenu = (activeKey) => {
    this.setState({ menuOpen: false, inverse: this.props.inverse, activeMenu: activeKey});
  };

  handleScroll = () => {
    const scrollY = window.scrollY;
    const shouldApplyScrollClass = scrollY > 0; // Modify this condition based on when you want to apply the class

    this.setState({
      scrolled: shouldApplyScrollClass,
    });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { menuOpen, activeMenu } = this.state
    const { navigation, className } = this.props

    const isFloating = this.state.floating
    const isInverse = this.state.inverse

    return (
      <TopWrapper className={className} floating={isFloating}>
        { !isFloating &&
          <Spacer />
        }
        <Header id="desktopHeader"
                menuOpen={menuOpen}
                inverse={isInverse}
                floating={isFloating}
                onMouseLeave={this.closeMenu}
                scrolled={this.state.scrolled}>
          <TopBar>
            <NavBar>
            <Proluxe color={ menuOpen || this.state.scrolled || isInverse ? "black" : "white" } />

              <NavBarCenter menuOpen={menuOpen} scrolled={this.state.scrolled} inverse={isInverse}>
                <DropDownMenu inverse={isInverse}
                              onMenuOpen={this.openMenu}
                              onMenuClosed={this.closeMenu}
                              activeMenu={activeMenu}
                              navigation={navigation}
                              scrolled={this.state.scrolled}
                              menuOpen={menuOpen} />
              </NavBarCenter>

              

              <NavBarRight>
                <ContactUs floating={isFloating} menuOpen={menuOpen}  scrolled={this.state.scrolled} />
              </NavBarRight>
            </NavBar>
          </TopBar>
        </Header>
      </TopWrapper>
    );
  }
}



export default DesktopNav;
