import React, { Component } from 'react';
import Link from 'components/Theme/Link';
import { styled } from 'components/Theme/Styles';

const Item = styled.li`
  padding-top: 0.5rem;
  font-size: 1.4rem;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  
  a {
    color: ${props => props.theme.black};
  }
`;

class SubNavItem extends Component {
  render() {
    return (
      <Item className={this.props.className}>
        { this.props.to ? <Link to={this.props.to || ''}>{this.props.children}</Link> : this.props.children }
      </Item>
    );
  }
}

export default SubNavItem;
