import React, { Component } from 'react';
import _ from 'lodash';
import Config from '../config'

export const variablesToObject = (variables, type) => {
  let defaults = {};
  let vars = {};
  defaults[type] = Config.get(type);

  (variables || []).map((variable) => {
    let val = variable.value

    if (variable.value === 'true') {
      val = true
    } else if (variable.value === 'false') {
      val = false
    }

    return _.set(vars, variable.key, val)
  })

  let attrs = _.merge(defaults, vars);
  return attrs
}

export default function variablesToProps(type) {
  if (!type) {
    throw new Error("Type such as 'openGraph' or 'schemaOrganization' is not defined for configToProps");
  }
  return (BaseComponent) => {

    return class extends Component {
      render() {
        let attrs = variablesToObject(this.props.variables, type)

        return (
          <BaseComponent {...this.props} {...attrs} />
        );
      }
    }
  }
}
