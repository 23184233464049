export default class Index {
  static defaults = {
    title: process.env.GATSBY_SITE_NAME,
    siteUrl: process.env.GATSBY_SITE_URL,
    siteName: process.env.GATSBY_SITE_NAME,
    hostname: process.env.GATSBY_HOSTNAME,
    locale: "en_US",
    metaDescription: "",
    image: "",
    openGraph: {
      fbAppId: process.env.GATSBY_FB_APP_ID || "",
      image: "",
      imageDescription: "",
      imageHeight: "",
      imageWidth: "",
    },
    twitter: {
      site: "https://twitter.com/proluxe", // organization twitter
      creator: "", // content creator
      image: ""
    },
    schemaOrganization: {
      name: "Proluxe",
      url: "https://www.proluxe.com",
      logo: "",
      street: "20281 Harvill Ave",
      city: "Perris",
      state: "CA",
      zip: "92570",
      country: "USA",
      email: "info@proluxe.com",
      description: "",
      foundingDate: "1973",
      sameAs: [ "https://www.doughpro.com" ],
      contacts: [
        {
          phone: "(800) 624-6717",
          type: "customer service", // Example: "customer service" - Supported types https://developers.google.com/search/docs/data-types/corporate-contact
          areaServed: [ "US, CA" ], // Array or String - US, CA
        },
        {
          phone: "(800) 624-6717",
          type: "sales", // Example: "customer service" - Supported types https://developers.google.com/search/docs/data-types/corporate-contact
          areaServed: [ "US, CA" ], // Array or String - US, CA
        }
      ]
    },
    schemaWebpage: {
      significantUrl: "",
      breadcrumbs: []
    },
    schemaArticle: {
      headline: "",
      image: "",
      author: "",
      createdAt: "",
      updatedAt: ""
    },
    schemaItemList: {
      items: [{
        breadcrumb_position: 0,
        url: ""
      }]
    },
    schemaPerson: {
      name: "",
      url: "",
      image: "",
      sameAs: [ "" ]
    },
    products: []
  };

  static set(item, value) {
    if (typeof item === "string") {
      this.defaults[item] = value;
    } else {
      this.defaults = Object.assign({}, this.defaults, item);
    }

    return true;
  }

  static get(key) {
    return this.defaults[key];
  }
}
