import React, { Component } from 'react';
import variablesToProps from 'lib/variablesToProps';
import Helmet from 'react-helmet';

class SchemaItemList extends Component {

  render() {
    const {
      schemaItemList
    } = this.props

    const {
      items
    } = schemaItemList

    let schema = {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        "@context": "http://schema.org",
        "@type": "ItemList",
        "itemListElement": items.map((item, i) => {
          return {
            "@type": "ListItem",
            "position": `${i}`,
            "url": `${item.url}`
          }
        })
      })
    };

    return (
      <Helmet script={[schema]} />
    )
  }
}


export default variablesToProps('schemaItemList')(SchemaItemList);
