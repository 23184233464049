import React, { Component } from 'react';
import Helmet from 'react-helmet';
import variablesToProps from 'lib/variablesToProps';

class OpenGraph extends Component {
  render() {
    const {
      title,
      description,
      pageImage,
      openGraph
    } = this.props

    const {
      fbAppId,
      type,
      siteName,
      locale,
      url
    } = openGraph

    let image = pageImage && pageImage.file && pageImage.file.url;
    let imageHeight = image && pageImage.file.details && pageImage.file.details.image.height;
    let imageWidth = image && pageImage.file.details && pageImage.file.details.image.width;

    return (
      <Helmet
        meta={[
          { key: 'og_title', property: 'og:title', content: title },
          { key: 'og_description', property: 'og:description', content: description },
          { key: 'og_image', property: 'og:image', content: image },
          { key: 'og_image_height', property: 'og:image:height', content: imageHeight },
          { key: 'og_image_width', property: 'og:image:width', content: imageWidth },
          { key: 'fb_app_id', property: 'fb:app_id', content: fbAppId },
          { key: 'og_type', property: 'og:type', content: type },
          { key: 'og_site_name', property: 'og:site_name', content: siteName },
          { key: 'og_locale', property: 'og:locale', content: locale },
          { key: 'og_url', property: 'og:url', content: url },
        ]}
      />
    )
  }
}


export default variablesToProps('openGraph')(OpenGraph);

