import React, { Component } from 'react';
import { graphql } from 'gatsby';
import ThemeSection from 'components/Theme/Block/Section'
import Background from './Background';
import Headline from './Headline';
import Body from './Body'
import Cta from './Cta'

class Section extends Component {

  render() {
    const { justify, align, innerPadding, background, headlines, body, cta, innerWidth } = this.props;
    const { backgroundColor, backgroundVideo, backgroundImage, backgroundMargin, backgroundOpacity } = Background.props(background)
    
    return (
      <ThemeSection justify={justify}
                    align={align}
                    innerPadding={innerPadding}
                    backgroundColor={backgroundColor}
                    backgroundVideo={backgroundVideo}
                    backgroundImage={backgroundImage}
                    backgroundMargin={backgroundMargin}
                    backgroundOpacity={backgroundOpacity}
                    innerWidth={innerWidth}>

        {(headlines || []).map((headlineItem, i) => {
          return <Headline key={`headline_${i}`} {...headlineItem} />
        }) }

        {(body || []).map((bodyItem, i) => {
          return <Body key={`body_${i}`} {...bodyItem} />
        }) }

        {(cta || []).map((ctaItem, i) => {
          return <Cta key={`cta_${i}`} {...ctaItem} />
        }) }
      </ThemeSection>
    )
  }
}

export default Section;

// language=GraphQL
export const sectionFragment = graphql`
  fragment Section on ContentfulBlockSection {
    justify
    align
    innerPadding
    innerWidth
    background {
      ...Background
    }
    
    headlines {
      ...Headline  
    }
    
    body {
      ...Form
      ...List
      ...Faq
      ...RichText
      ...RelatedPages
      ...ProductCollections
    }
    
    cta { 
      ...Cta
    }
  }
`;