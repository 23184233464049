import React, { Component } from 'react';
import 'components/Theme/Fonts.css'
import 'components/Theme/Globals';
import Link from 'gatsby-link';
import { styled, media } from 'components/Theme/Styles';
import ThemeHeadline from 'components/Theme/Headline';

const Hero = styled.div`
  height:50vh;
  background-color:${p => p.theme.darkGray};
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const Main = styled.div`
  display:flex;
  min-height:70vh;
  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`;

const Sidebar = styled.div`
  width:30rem;
  background-color:${p => p.theme.lightGrey};
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const Nav = styled.ul`
  display:flex;
  width:100%;
  flex-direction:column;
  padding-top:2rem;
  ${media.lessThan("tablet")`
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:center;
    padding-bottom:2rem;
  `}
`;

const NavItem = styled.li`
  font-size:1.6rem;
  padding:2rem 4rem;

  background-color:${p => p.selected ? p.theme.white : 'transparent'};
  
  a {
    ${media.lessThan("tablet")`
      text-decoration:underline;
    `}
  }
`;

const Content = styled.div`
  width:100%;
`;

const ContentShim = styled.div`
  width:100%;
`;


class ServiceLayout extends Component {
  render() {
    const { selected } = this.props;
    return (
      <>
      <Hero>
        <ThemeHeadline h2 color={`white`}>{this.props.heroTitle}</ThemeHeadline>
      </Hero>
      <Main>
        <Sidebar>
          <Nav>
          <Link to={`/pages/find-service`}><NavItem selected={selected == "Find Service"}>Find Service</NavItem></Link>
          <Link to={`/pages/get-service`}><NavItem selected={selected == "Get Service"}>Get Service</NavItem></Link>
          <Link to={`/pages/serial-number-lookup`}><NavItem selected={selected == "Serial Number Lookup"}>Serial Number Lookup</NavItem></Link>
          <Link to={`/pages/maintenance`}><NavItem selected={selected == "Maintenance"}>Maintenance</NavItem></Link>
          <Link to={`/pages/parts`}><NavItem selected={selected == "Parts"}>Parts</NavItem></Link>
          <Link to={`/pages/returns`}><NavItem selected={selected == "Returns"}>Returns</NavItem></Link>
          <a href={`tel:18006246717`}><NavItem>1-800-624-6717</NavItem></a>
          <a href={`mailto:service@proluxe.com`}><NavItem>service@proluxe.com</NavItem></a>
          </Nav>
        </Sidebar>

        <Content>
          <ContentShim>
            { this.props.children }
          </ContentShim>
        </Content>

      </Main>
      </>
    )
  }
}

export default ServiceLayout


