import React, { Component } from 'react';
import { graphql } from 'gatsby';
import RichText from 'components/Theme/RichText';
import Meta from 'components/Meta'
import { styled } from 'components/Theme/Styles';
import SimpleBackButton from 'components/Header/SimpleBackButton';
import Layout from 'components/Layouts/Default';

const Content = styled.div`
  max-width:90rem;
  margin:15vh auto;
`;

class Legal extends Component {
  render() {
    const {
      title,
      description,
      searchEngineFeatures,
      robotsAdvance,
      canonicalUrl,
      pageImage,
      meta} = this.props.data.contentfulPage;

    let descriptionText = description && description.childMarkdownRemark;
    let bodyText = descriptionText.html

    return (
      <Layout>
        <Meta title={title}
              description={descriptionText}
              searchEngineFeatures={searchEngineFeatures}
              robotsAdvance={robotsAdvance}
              canonicalUrl={canonicalUrl}
              pageImage={pageImage}
              variables={meta && meta.variables}
              schemaOrganization
              schemaWebpage
              openGraph
              twitter />

        <SimpleBackButton />

        <Content>
          <RichText body={bodyText} />
        </Content>

      </Layout>
    );
  }
}

export default Legal;

// language=GraphQL
export const legalQuery = graphql`
  query LegalQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }
    }
  }
`;


