import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby'
import ThemeHeadline from 'components/Theme/Headline';
import MarqueeHeadline from 'components/Theme/MarqueeHeadline';

const HeadlineType = (props) => (
  <Fragment>
    { props.marqueeText ? <MarqueeHeadline {...props} /> : <ThemeHeadline {...props} /> }
  </Fragment>
)
class Headline extends Component {

  render() {
    const { title, textColor, size, transform, weight, marginBottom, marginTop, marqueeText, backgroundColor } = this.props
    return (
       <HeadlineType size={size}
                     transform={transform}
                     marginTop={marginTop}
                     marginBottom={marginBottom}
                     backgroundColor={backgroundColor}
                     weight={weight}
                     color={textColor}
                     marqueeText={marqueeText}>{title}</HeadlineType>
    )
  }
}

export default Headline;

// language=GraphQL
export const headlineFragment = graphql`
  fragment Headline on ContentfulSectionHeadline {
    title
    textColor
    size
    weight
    transform
    marginTop
    marginBottom
    marqueeText
    backgroundColor
  }
`;