import React, { Component } from 'react';
import { styled, media } from 'components/Theme/Styles';
import { legacyFixedImage } from 'utils/legacyImage'
import ThemeBlock, { Section } from 'components/Theme/Block';
import ThemeHeadline from 'components/Theme/Headline';
import ThemeButton from 'components/Theme/Button';
import { GatsbyImage } from "gatsby-plugin-image"

const Headline = styled(ThemeHeadline)`

`;

const ProductsBlock = styled(ThemeBlock)`
  ${media.lessThan("tablet")`
    flex-direction:column-reverse;
    padding-bottom:7rem;
  `}

  border-bottom:0.1rem solid ${p => p.theme.grey};

`;

const Primary = styled(Section)`
  background-color: ${p => p.color};
`;

const Secondary = styled(Section)`
  background-image: url('${p => p.src}');

  ${media.lessThan("tablet")`
    zoom: 0.8;
    & > div {
      justify-content:flex-end;
    }
  `}
`;

const Copy = styled(ThemeHeadline)`
  color:${p => p.theme.darkGray};
  margin-bottom:2rem;
`;

const Button = styled(ThemeButton)`
  margin-top:2rem;
`;

class ProductListItem extends Component {
  static currentPath() {
    if (typeof window === 'object') {
      return window.location.pathname
    }
  }

  render() {
    const { title, description, handle, images } = this.props
    
    return (
      <ProductsBlock height="Large">

      <Primary align="left" justify="Top" innerPadding="Large">

        <Headline h2>{title}</Headline>
        <Copy h4>{description}</Copy>

        <Button to={`/products/${handle}`}>Details</Button>
      </Primary>

      <Secondary justify="Top">
        { images &&
          <GatsbyImage alt={title} image={images[0].gatsbyImageData} />
        }
      </Secondary>
    </ProductsBlock>
    );
  }
}

export default ProductListItem;

