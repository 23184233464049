import React, { Component } from 'react';
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { styled, media } from 'components/Theme/Styles';
import ThemeHeadline from '../Theme/Headline'
import Link from 'components/Theme/Link';


const RelatedPage = styled.div`
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin-top:4rem;
  
  ${media.lessThan("tablet")`
    flex-direction: column;
    align-items: center;
  `}
`;

const Page = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width:calc(33% - 5rem);
  overflow: hidden;
  
  ${media.lessThan("tablet")`
    width:calc(100% - 5rem);
    justify-content: center;
    align-items:center;
    margin-bottom: 5rem;
    text-align:center;
  `}
`;

const PageImage = styled.div`
  object-fit: cover;
  object-position: top 75%;
  ${media.lessThan("tablet")`
    .gatsby-image-wrapper {
      height: 25rem !important;
    }
  `}
`;

const PageHeadline = styled(ThemeHeadline)`
  margin:1rem 0;
`;

const PageLink = styled(Link)`
  font-size:${p => p.theme.h5FontSize}rem;
  color:${p => p.theme.brandPrimary} !important;
`;

const PageCard = (props) => (
  <Page>
    <PageImage><GatsbyImage image={props.pageImage.gatsbyImageData} /></PageImage>
    <PageHeadline h4>{props.title}</PageHeadline>
    <PageLink to={`/pages/${props.handle}`}>Read more</PageLink>
  </Page>
)

class RelatedPages extends Component {

  render() {
    const { pages } = this.props

    return (
      <RelatedPage>
        {(pages || []).map((page, i) => {
          return <PageCard {...page} key={`page_${i}`} />
        })}
      </RelatedPage>
    )
  }
}

export default RelatedPages;


export const relatedPagesFragment = graphql`
  fragment RelatedPages on ContentfulSectionRelatedPages {
    __typename
    pages {
      handle
      title
      pageImage {
        gatsbyImageData
      }
    }
  }
`;