import React, { Component } from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import { styled, media } from 'components/Theme/Styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from 'styled-icons/material'
import Swipeable from 'react-swipeable'
import { legacyFixedImage } from 'utils/legacyImage'


const Container = styled.div`
  display:flex;
  flex-direction:row-reverse;
  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`;


const ImageWrapper = styled.div`
  background-color:#f8f8f8;
  ${media.lessThan("tablet")`
    zoom: 0.8;
    margin-bottom:2rem;
  `}
`;

const Thumbs = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:25rem;
  ${media.lessThan("tablet")`
    flex-direction:row;
    width:100%;
  `}
`;

const Thumb = styled.div`
  padding:0.5rem;
  margin-right:0.5rem;
  border:1px solid #ccc;
  margin-bottom:1rem;
  text-align:center;
  cursor:pointer;
  width:6rem;
  height:6rem;
  border-radius: 0.2rem;
`;


class Carousel extends Component {
  state = {
    currentSlide: 0
  }

  nextSlide = () => {
    this.setState({currentSlide: this.incrementSlide()})
  }

  prevSlide = () => {
    this.setState({currentSlide: this.decrementSlide()})
  }

  goToSlide = (i) => {
    this.setState({currentSlide: i})
  }

  incrementSlide() {
    let total = this.props.images.length
    if ((total) === (this.state.currentSlide + 1)) {
      return 0
    } else {
      return this.state.currentSlide + 1
    }
  }

  decrementSlide() {
    let total = this.props.images.length
    if ((this.state.currentSlide - 1) === -1) {
      return total - 1
    } else {
      return this.state.currentSlide - 1
    }
  }

  showImage(images, slide, options={}) {
    let image = images[slide]
    return image && <GatsbyImage alt={image.description} image={image.gatsbyImageData} style={options.style} imgStyle={options.style}  />
  }

  render() {
    const { images } = this.props;
    

    return (
      <Container>
        <div>
          <ImageWrapper>
            { this.showImage(images, this.state.currentSlide) }
          </ImageWrapper>
        </div>
        <Thumbs>
          { this.props.images.map((image, i) => {
              return (
                <Thumb key={`thumb_${i}`} onClick={ () => this.goToSlide(i) }>{ this.showImage(images, i, { style: { height: '5rem', width: '5rem' } }) }</Thumb>
              ) 
          }) }
        </Thumbs>
      </Container>
    )
  }
}

export default Carousel;

