import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ServiceLayout from 'components/Layouts/Service';
import Layout from 'components/Layouts/Default';

import _ from 'lodash';
import SeriesView from 'components/Theme/SeriesView';
import ProductCard from 'components/Theme/ProductCardParts';

class Parts extends Component {

  render() {
    const collections = this.props.data.allShopifyCollection.edges
    console.log(collections)
    return (
      <Layout>
        <Header floating={true} />
        <ServiceLayout selected="Parts" heroTitle="Parts">
          <div>
            { _.compact(collections.map((edge) => {
                return (
                  <SeriesView {...edge.node}>
                    {(edge.node.products||[]).map((product, i) => {
                      return <ProductCard key={`product_${i}`} product={product}  />
                    })}
                  </SeriesView>    
                )
              })) 
            }
          </div>

        </ServiceLayout>

        <Footer />
      </Layout>
    );
  }
}

export default Parts;


// language=GraphQL
export const pageQuery = graphql`
  query PartsQuery {
    contentfulPage(handle: { eq: "parts" }) {
      title
      description {
        description
      }
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }
      showSocialButtons
      blocks {
        ...Blocks
      }
      variables {
        key
        value
      }
    }

    allShopifyCollection(filter: {title: {regex: "/(S|s)eries/"}}, sort: {order: DESC, fields: products___variants___children}) {
      edges {
        node {
          title
          handle
          description
          products {
            title
            handle
            variants {
              sku
              image {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

