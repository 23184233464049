export const currentPath = () => {
  if (typeof window === 'object') {
    return window.location.pathname
  }
}

export const currentHref = () => {
  if (typeof window === 'object') {
    return window.location.href
  }
}
