import React, { Component } from 'react';
import HamburgerNav, { HamburgerNavItem } from './HamburgerNav';

class SupportMenu extends Component {

  render() {
    return (
      <HamburgerNav text="Service" open={true}>
        <ul>
          <HamburgerNavItem><a href="/pages/find-service">Find Service</a></HamburgerNavItem>
          <HamburgerNavItem><a href="/pages/maintenance">Maintenance</a></HamburgerNavItem>
          <HamburgerNavItem><a href="/pages/parts">Parts</a></HamburgerNavItem>
          <HamburgerNavItem><a href="tel:1-800-624-6717">Call: 1.800.624.6717</a></HamburgerNavItem>
          <HamburgerNavItem><a href="mailto:support@proluxe.com">Email: support@proluxe.com</a></HamburgerNavItem>
        </ul>
      </HamburgerNav>
    );
  }
}

export default SupportMenu;
