import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import _ from 'lodash';
import ProductCard from 'components/Theme/ProductCard';
import SeriesView from 'components/Theme/SeriesView';
class ProductCollections extends Component {

  render() {
    const { collections } = this.props

    return (
      <div>
        <StaticQuery query={graphql`
        query ShopifyCollections {
          allShopifyCollection {
            edges {
              node {
                title
                handle
                products {
                  title
                  handle
                  variants {
                    sku
                    image {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }`}
       render={data => {
         return _.compact(data.allShopifyCollection.edges.map((edge) => {
            if (collections.includes(edge.node.title)) {
              return <SeriesView {...edge.node}>
                {(edge.node.products||[]).map((product, i) => {
                  return <ProductCard key={`product_${i}`} product={product} to={`/products/${product.handle}`} />
                })}
              </SeriesView>  
            } else {
              return null
            }
         })) 
       }} />
      </div>
    )
  }
}

export default ProductCollections;


export const productCollectionsFragment = graphql`
  fragment ProductCollections on ContentfulSectionProductCollections {
    __typename
    collections 
  }
`;