import React, { Component } from 'react';
import SubNav, { SubNavGroup, SubNavItem } from './SubNav';
import { styled } from 'components/Theme/Styles';
import Link from 'components/Theme/Link';
import { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight'
import { handleIt } from 'utils/titlize';

const SuperGroup = styled.div`
  
`;

const Arrow = styled(KeyboardArrowRight)`
  height:2.4rem;
`;

const SuperGroupHeadline = styled.div`
  border-bottom:0.2rem solid #f1f1f1;
  padding-bottom:2rem;
  width:calc(100% - 3rem);
  margin-bottom:1.5rem;
  white-space: nowrap;
`;

const GroupWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  white-space: nowrap;
  min-width:${p => p.width}vw;
`;

const Headline = styled.h3`
  font-size:${p => p.theme.h4FontSize}rem;
  font-weight:400;
  margin:0 0 0 0;
`;

const ModelNumber = styled.div`
  font-size:1.2rem;
  color:${p => p.theme.mediumGrey};
`;

class ProductMenu extends Component {

  totalCategories = () => {
    const { categories } = this.props.navigationData;
    let total = 0;

    
    (categories || []).map((category, i) => {
      return total += 1
    })

    return total
  }

  totalLists = () => {
    const { categories } = this.props.navigationData;
    let total = 0;

    
    (categories || []).map((category, i) => {
      return (category.lists || []).map((list, ii) => {
        return total += 1
      })
    })

    return total
  }

    


  render() {
    const { navigationData } = this.props;
    const { topLevelName, categories } = navigationData;

    let totalLists = this.totalLists()
    let totalCategories = this.totalCategories()
    

    return (
      <SubNav text={topLevelName} {...this.props}>
        {(categories || []).map((category, i) => {
          return (
            <SuperGroup key={`productCategory_${i}`}>
              <SuperGroupHeadline>
                <Headline><Link to={`/pages/${handleIt(category.name)}`}>{category.name}</Link> <Arrow /></Headline>
              </SuperGroupHeadline>

              <GroupWrapper width={100 / ( Math.max(totalLists, totalCategories) || 1)}>
                {(category.lists || []).map((list, ii) => {
                  return <Group title={list.title}
                                to={`/pages/${handleIt(category.name)}#${list.handle}`}
                                image={list.image}
                                products={list.products}
                                key={`productList_${ii}`}
                                totalLists={ totalLists }
                                totalCategories={ totalCategories } />
                })}
              </GroupWrapper>
            </SuperGroup>
          )
        })}

      </SubNav>
    );
  }
}

const Group = (props) => {
  const { products, title, image, to, totalLists, totalCategories } = props

  return (
    <SubNavGroup title={title} to={to} image={image} totalLists={Math.max(totalLists, totalCategories)}>
      
    </SubNavGroup>
  )
}

const Product = (props) => {
  const { product } = props

  return (
    <SubNavItem to={`/products/${product.handle}`}>
      {product.title}
      <ModelNumber>{product.variants[0].sku}</ModelNumber>
    </SubNavItem>
  )
}

export default ProductMenu;


