import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Meta from 'components/Meta';
import { variablesToObject } from 'lib/variablesToProps';
import Layout from 'components/Layouts/Default';
import { styled, media } from 'components/Theme/Styles';
import ThemeHeadline from 'components/Theme/Headline';
import ThemeButton from 'components/Theme/Button';
import Video from 'components/Theme/Video';
import { GatsbyImage } from "gatsby-plugin-image"

const HeroContainer = styled.div`
  height:90vh;
  display:flex;
  justify-content:${p => p.align || 'flex-start'};
  align-items:${p => p.align || 'flex-start'};
  flex-direction:column;
  position:relative;


  ${media.lessThan("tablet")`
    height:80vh;
    flex-direction:column;
  `}
  ${media.greaterThan("tablet")`
    ${p => p.mainHero && `
      // Adding the shadow gradient
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width:100%;
        height: 20vh; // Adjust the height as needed
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
        
        z-index: 1; // Ensure it's above the background but below the text
      }
    `}
  `};
  ${media.between("tablet", "desktop")`

  `};
`;

const HeroAsset = styled(GatsbyImage)`
  width:100%;
  position:absolute !important;
  z-index:0;
  height:90vh;
  
  
  ${media.lessThan("tablet")`
    height:80vh;
    position:relative !important;
    img {
      object-position: ${p => p.mobilePosition ? p.mobilePosition : '50%'};
    }
  `}
`;

const HeroVideo = styled(Video)`
  width:100%;
  position:absolute !important;
  z-index:0;
  height:80vh;
`;

const HeroCopy = styled.div`
  display:flex;
  justify-content:center;
  align-items:${p => p.align || 'flex-start'};
  text-align:${p => p.align || 'left'};
  flex-direction:column;
  height:100%;
  width:100%;
  padding:10rem;
  
  ${media.lessThan("tablet")`
    width:100%;
    padding:2rem;
    justify-content:center;
    align-items:center;
  `};
`;

const SubHeroCopy = styled(HeroCopy)`
    width:50%;

    ${media.lessThan("tablet")`
      width:100%;
      padding:2rem;
      justify-content:center;
      align-items:center;
      background-color:${p => p.mobileBackgroundColor || '#fff'};
  `};
`;

const HeroHeadline = styled(ThemeHeadline)`
  color:${p => p.color || '#fff'};
  font-size:8rem;
  line-height:6rem;
  margin-bottom:2rem;
  

  ${media.lessThan("tablet")`
    font-size:6rem;
    line-height:5.5rem;
    text-align:center;
  `};
`;

const HeroSubheadline = styled(ThemeHeadline)`
  color:${p => p.color || '#fff'};
  font-size:2.4rem;
  width:80%;
  line-height:3rem;
  margin-bottom:2rem;
  text-transform:uppercase;
  ${media.lessThan("tablet")`
    text-align:center;
  `};
`;


const SubHeroHeadline = styled(HeroHeadline)`
  font-size:6rem;

  ${media.lessThan("tablet")`
    font-size:5rem;
  `};
`;

const SubHeroSubheadline = styled(HeroSubheadline)`
  font-size:2.2rem;
  ${media.lessThan("tablet")`
  font-size:1.8rem;
  `};
`;

const SuperHeroSubheadline = styled(HeroSubheadline)`
  font-size:2rem;
  color:${p => p.theme.brandWarning};
`;


const CarbonLogo = styled.img`
  z-index:1;
  width:25rem;
  margin-bottom:4rem;
  
`;



class Home extends Component {

  render() {
    const {
      title,
      description,
      pageImage,
      heroAsset,
      heroHeadline,
      heroSubheadline,
      navigationBubbles,
      section1Asset,
      section1Superheadline,
      section1Headline,
      section1Description,
      section2Asset,
      section2Superheadline,
      section2Headline,
      section2Description,
      section3Asset,
      section3Superheadline,
      section3Headline,
      section3Description,
      logos,
      testimonial,
      testimonialPersonsName,
      internationalAsset,
      internationalHeadline,
      internationalSubheadline,
      blogAsset,
      blogHeadline,
      blogSubheadline,
      variables,
      meta } = this.props.data.contentfulHomepage;

    let descriptionText = description && description.description;
    let pageVariables = variablesToObject(variables, 'page').page;
    let headerProps = pageVariables && pageVariables.header;
    let footerProps = pageVariables && pageVariables.footer;

    return (
      <Layout>
        <Meta title={title}
              description={descriptionText}
              searchEngineFeatures={meta.searchEngineFeatures}
              robotsAdvance={meta.robotsAdvance}
              canonicalUrl={meta.canonicalUrl}
              pageImage={pageImage}
              variables={meta.variables}
              schemaWebpage
              schemaOrganization
              openGraph
              twitter />

        <Header {...headerProps} />

        <div id="content">
        <HeroContainer align="center" mainHero="true">
          <HeroVideo src={heroAsset.file.url} />

          <HeroCopy align="center">
            <HeroHeadline h1 color="white">{heroHeadline}</HeroHeadline>
            <HeroSubheadline h2 color="white">{heroSubheadline}</HeroSubheadline>
            <ThemeButton to={`pages/tavern-style-pizza`}>Learn More</ThemeButton>
          </HeroCopy>
        </HeroContainer>

      
        <HeroContainer align="flex-end">
          <HeroAsset mobilePosition="0" image={section1Asset.gatsbyImageData} />

          <SubHeroCopy mobileBackgroundColor="#efefef">
            <SubHeroHeadline h1 color="black">{section1Headline}</SubHeroHeadline>
            <SubHeroSubheadline h2 color="black">{section1Description}</SubHeroSubheadline>
            <ThemeButton to={`/pages/dough-presses`}>Learn More</ThemeButton>
          </SubHeroCopy>
        </HeroContainer>

        <HeroContainer>
          <HeroAsset image={section3Asset.gatsbyImageData} mobilePosition="70%" />

          <SubHeroCopy mobileBackgroundColor="#111">
            <SubHeroHeadline h1 color="white">{section3Headline}</SubHeroHeadline>
            <SubHeroSubheadline h2 color="white">{section3Description}</SubHeroSubheadline>
            <ThemeButton to={`/pages/contact-grills`}>Learn More</ThemeButton>
          </SubHeroCopy>
        </HeroContainer> 

        <HeroContainer align="flex-end">
          <HeroAsset mobilePosition="25%" image={section2Asset.gatsbyImageData} />

          <SubHeroCopy mobileBackgroundColor="#efefef">
            <SuperHeroSubheadline h4 color="black">{section2Superheadline}</SuperHeroSubheadline>
            <SubHeroHeadline h1 color="black">{section2Headline}</SubHeroHeadline>
            <SubHeroSubheadline h2 color="black">{section2Description}</SubHeroSubheadline>
            <ThemeButton to={`/pages/contact-toasters`}>Learn More</ThemeButton>
          </SubHeroCopy>
        </HeroContainer>

        <HeroContainer>
          <HeroAsset image={internationalAsset.gatsbyImageData} />

          <SubHeroCopy mobileBackgroundColor="#111">
            <CarbonLogo src="https://cookwithcarbon.com/carbon-logo-transparent-inverse.png" /> 

            <SubHeroSubheadline h2 color="white">{internationalSubheadline}</SubHeroSubheadline>
            <ThemeButton to={`https://cookwithcarbon.com/commercial`} target="_blank">Learn More</ThemeButton>
          </SubHeroCopy>
        </HeroContainer>



        </div>
        <Footer hideNewsletterSignup={footerProps && footerProps.hideNewsletterSignup} />
      </Layout>
    );
  }
}

export default Home;

// language=GraphQL
export const pageQuery = graphql`
  query HomepageQuery {

    contentfulGlobals(name: {eq: "Default" }) {
      topLevelNavOrder
      categoryListNavOrder
      collectionListNavOrder
    }

    allContentfulNavItem {
      edges {
        node {
          topLevelName
          category
          shopifyCollectionName
        }
      }
    }

    contentfulHomepage(id: { eq: "c52bea22-6ad6-55d0-85fa-4c2813771ea8" }) {
      title
      description {
        description
      }
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }

      heroHeadline
      heroSubheadline
      heroAsset {
        gatsbyImageData
        file {
          url
        }
      }

      navigationBubbles {
        label
        asset {
          gatsbyImageData
        }
        link
      }

      section1Asset {
        gatsbyImageData
      }

      section1Superheadline
      section1Headline
      section1Description

      section2Asset {
        gatsbyImageData
      }

      section2Superheadline
      section2Headline
      section2Description

      section3Asset {
        gatsbyImageData
      }

      section3Superheadline
      section3Headline
      section3Description

      logos {
        gatsbyImageData
      }

      testimonial
      testimonialPersonsName

      internationalHeadline
      internationalSubheadline
      internationalAsset {
        gatsbyImageData
      }

      blogHeadline
      blogSubheadline
      blogAsset {
        gatsbyImageData
      }

      variables {
        key
        value
      }
    }
  }
`;

