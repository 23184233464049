import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';


const AddToCart = styled.div`
  width:100rem !important;
  .shopify-buy__btn {
    text-align: center;
    color: white;
    background: linear-gradient(#075EDB 0, #2B5E9D 100%);
    text-transform: uppercase;
    min-height: auto;
    min-width: auto;
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    padding: 1rem 2rem 1rem 2rem; // em for relative padding based on font size
    display: inline-flex;
    align-items: center;
    cursor:pointer;
    position:relative;
    border: 0.1rem solid transparent;
    border-radius:1rem;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform opacity;
    transition-property: transform opacity;

  &:hover, &:focus, &:active {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
    opacity:0.9;
  }
    &:focus {
      outline:0;
    }
  }

  .shopify-buy__btn-disabled {
    box-shadow:none;
  }

`;

const productOptions = (ui) => {
  return {
    option: {
      templates: {
        option: '' +
          '<div class="{{data.classes.option.wrapper}}">' +
            '<p class="{{data.classes.option.label}}">{{data.name}}</p>' +
            '<div>' +
              '{{#data.values}}' +
              '<button {{#data.styleAttr}} {{name}} {{/data.styleAttr}} data-value="{{name}}" data-option={{data.name}} class="{{#disabled}}{{data.classes.option.optionDisabled}}{{/disabled}} {{#selected}}{{data.classes.option.optionSelected}}{{/selected}} {{data.classes.option.option}}">{{#data.optionName}}{{name}}{{/data.optionName}}</button>' +
              '{{/data.values}}' +
            '</div>' +
          '</div>'
      },
      styles: {
        wrapper: {
          'padding-bottom': '10px',
          'border': 0,
        },
        label: {
          'margin-top': '0'
        },
        option: {
          'border': '1px solid #c7c2c0',
          'display': 'inline-block',
          'border-radius': '1rem',
          'margin-top': '0!important',
          'margin-right': '5px',
          'background-color': '#fff',
          'padding': '10px',
          'cursor': 'pointer'
        },
        optionDisabled: {
          'opacity': '0.2',
          'position': 'relative',
          ':before': {
            'content': '""',
            'position': 'absolute',
            'height': '60px',
            'width': '1px',
            'background': 'black',
            'top': '-8px',
            'left': '21px',
            'transform': 'rotate(45deg)'
          }
        },
        optionSelected: {
          'border-color': 'black'
        }
      }
    },
    product: {
      DOMEvents: {
        'click .shopify-buy__option-select': function (evt, target) {
          var data = target.dataset;
          var product = ui.components.product[0];
          product.updateVariant(data.option, data.value);
          // update price
          if (typeof window !== `undefined`) {
            let __price = parseFloat(product.selectedVariant.price.amount).toFixed(2)
            document.getElementById('price').innerHTML = `${__price}`;
            document.getElementById('modelNumber').innerHTML = product.selectedVariant.sku;
          }
        }
      },
      viewData: {
        optionName: function () {
          return function (text, render) {
            var key = render(text).trim();
            return key;
          }
        }
      },
      styles: {
        product: {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px"
          }
        },
        buttonWrapper: {
          "text-align": "left"
        },
        button: {
          "font-family": "Proxima Nova, sans-serif",
          "text-align": "center",
          "color": "white",
          "background": "linear-gradient(#075EDB 0, #2B5E9D 100%)",
          "text-transform": "uppercase",
          "font-size": "0.8rem",
          "letter-spacing": "0.05rem",
          "padding": "0.8rem 1.6rem;",
          "display": "inline-flex",
          "align-items": "center",
          "cursor": "pointer",
          "position": "relative",
          "border": "0.1rem solid transparent",
          "border-radius":"0.6rem",
          "-webkit-transition-duration": "0.1s",
          "transition-duration": "0.1s",
          "-webkit-transition-property": "transform opacity",
          "transition-property": "transform opacity",
          ":hover": {
            "background-color": "#5280c6"
          },
          "background-color": "#5b8edc",
          ":focus": {
            "background-color": "#5280c6"
          }
        }
      },
      contents: {
        img: false,
        title: false,
        price: false
      },
      text: {
        "button": "Add to cart"
      },
      googleFonts: [
        "Lato"
      ]
    },
    productSet: {
      styles: {
        products: {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    modalProduct: {
      contents: {
        img: false,
        imgWithCarousel: true,
        button: false,
        buttonWithQuantity: true
      },
      styles: {
        product: {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        button: {
          "font-family": "Proxima Nova, sans-serif",
          "text-align": "center",
          "color": "white",
          "background": "linear-gradient(#075EDB 0, #2B5E9D 100%)",
          "text-transform": "uppercase",
          "font-size": "0.8rem",
          "letter-spacing": "0.05rem",
          "padding": "0.8rem 1.6rem;",
          "display": "inline-flex",
          "align-items": "center",
          "cursor": "pointer",
          "position": "relative",
          "border": "0.1rem solid transparent",
          "border-radius":"0.6rem",
          "-webkit-transition-duration": "0.1s",
          "transition-duration": "0.1s",
          "-webkit-transition-property": "transform opacity",
          "transition-property": "transform opacity",
          ":hover": {
            "background-color": "#5280c6"
          },
          "background-color": "#5b8edc",
          ":focus": {
            "background-color": "#5280c6"
          }
        }
      },
      googleFonts: [
        "Lato"
      ],
      text: {
        "button": "Add to cart"
      }
    },
    cart: {
      styles: {
        button: {
          "font-family": "Proxima Nova, sans-serif",
          "text-align": "center",
          "width": "100%",
          "color": "white",
          "background": "linear-gradient(#075EDB 0, #2B5E9D 100%)",
          "text-transform": "uppercase",
          "font-size": "0.8rem",
          "letter-spacing": "0.05rem",
          "padding": "0.8rem 1.6rem;",
          "align-items": "center",
          "cursor": "pointer",
          "position": "relative",
          "border": "0.1rem solid transparent",
          "border-radius":"0.6rem",
          "-webkit-transition-duration": "0.1s",
          "transition-duration": "0.1s",
          "-webkit-transition-property": "transform opacity",
          "transition-property": "transform opacity",
          ":hover": {
            "background-color": "#5280c6"
          },
          "background-color": "#5b8edc",
          ":focus": {
            "background-color": "#5280c6"
          }
        }
      },
      text: {
        total: "Subtotal",
        button: "Checkout"
      },
      popup: false,
      googleFonts: [
        "Lato"
      ]
    },
    toggle: {
      styles: {
        toggle: {
          "font-family": "Lato, sans-serif",
          "background-color": "#5b8edc",
          ":hover": {
            "background-color": "#5280c6"
          },
          ":focus": {
            "background-color": "#5280c6"
          }
        }
      },
      googleFonts: [
        "Lato"
      ]
    }
  }
}

const BuyButton = ({id}) => {
  const buyButton = useRef({});

  useEffect(() => {
    if(typeof window !== `undefined`) {
      let ui = window.shopifyUI
      ui.createComponent('product', {
        storefrontId: id,
        node: buyButton.current,
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: productOptions(ui),
      })
    }
  }, [])

  
  return (
    <AddToCart ref={buyButton} id="buy-button" />
  )
}


export default BuyButton;
