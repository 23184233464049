import { Component } from 'react'
import Validator from 'utils/validator'
import { css } from 'styled-components';
import { generateMedia } from "styled-media-query";

export const media = generateMedia({
  desktop: '78rem',
  tablet: '60rem',
  mobile: '46rem'
})

class Styles extends Component {


  static backgroundImage = (backgroundImage) => {
    if (Validator.isImage(backgroundImage)) {
      return `
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-image: url('${backgroundImage}');
      `;
    }
  };

  static backgroundColor = (backgroundColor) => {
    return Validator.isColor(backgroundColor) ? `
      background-color: ${backgroundColor};
  ` : null
  };

  static hidden = (hidden) => {
    if (hidden === true) {
      return css`display:none;`
    }
  };
}

export default Styles
export styled from 'styled-components';
export { css } from 'styled-components';
export { keyframes } from 'styled-components';