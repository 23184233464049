import React, { Component, Fragment } from 'react'
import { styled, media } from 'components/Theme/Styles';
import ThemeHeadline from 'components/Theme/Headline';
import ThemeBlock, { Section } from 'components/Theme/Block';
import Carousel from 'components/Theme/Carousel';
import Button from 'components/Theme/Button';
import RichText from 'components/Theme/RichText';
import BuyButton from 'components/Theme/BuyButton';
import { Link } from 'gatsby';
import { FilePdf } from 'styled-icons/fa-solid/FilePdf'


const OverviewBlock = styled(ThemeBlock)`
  margin-top:10rem;
  ${media.lessThan("tablet")`
    margin-top:0;
    min-height:auto;
  `}
`;

const Subheadline = styled(ThemeHeadline)`
  color:#999;
`;

const Description = styled(RichText)`
  h2 {
    font-size:2rem;
    margin:0 !important;
  }
`;

const OverviewSection = styled(Section)`
  margin-bottom:5rem;
  ${media.lessThan("tablet")`
    
  `}
`;

const CarouselSection = styled(Section)`
  
`;

const TitleWrapper = styled.div`
  width:100%;
`;

const Price = styled.div`
  font-size:2.4rem;
  padding-bottom:3rem;
  color:${p => p.theme.brandPrimary};
  line-height:4rem;
  &::before {
    content: "$";
  }
`;

const OverviewHeadline = styled(ThemeHeadline)`
  font-size:${p => p.theme.h2FontSize}rem;
  width: auto !important;
  line-height:5rem;
  ${media.lessThan("tablet")`
    margin:0 auto;
  `}
`;

const OverviewButton = styled(Button)`
  margin-top:2rem;
`;

const PdfIcon = styled(FilePdf)`
  color:red;
  width:12px;
  margin-right:0.3rem;
`;

const SpecSheet = styled(Link)`
  margin-top:2rem;
  font-size:1.6rem;
`;

class Overview extends Component {
  render() {
    const { name, modelNumber, price, description, carouselImages, buyButtonId, availableForECommerce, specSheet } = this.props
  

    return (
      <Fragment>
        <OverviewBlock id="overview">
          <OverviewSection>
          <CarouselSection>
            {carouselImages && (
              <Carousel images={carouselImages} />
            )}
          </CarouselSection>
          </OverviewSection>
          <OverviewSection innerPadding="Large" align="Left">
            <TitleWrapper>
              { availableForECommerce && <Price id="price">{price}</Price> }
              <OverviewHeadline h2>{name} </OverviewHeadline>
            </TitleWrapper>

            <Subheadline id="modelNumber" h4>{modelNumber}</Subheadline>

            <Description>{description}</Description>
            
            <div>
              { availableForECommerce && <BuyButton id={buyButtonId} priceElement={this.price} /> }
              { !availableForECommerce && <OverviewButton to={`/pages/contact-us?product=${modelNumber}`}>Talk to Sales</OverviewButton> }
            </div>

            {specSheet && 
            <SpecSheet href={`https://${specSheet.file.url}`} target="_blank"><PdfIcon /> Download Spec Sheet</SpecSheet>
            } 
          </OverviewSection>
        </OverviewBlock>
      </Fragment>
    )
  }
}

export default Overview;
