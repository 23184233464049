import React, { Fragment, Component } from 'react';
import ThemeRichText from 'components/Theme/RichText';


class RichText extends Component {

  render() {
    const { body } = this.props
    let bodyText = body.childMarkdownRemark.html

    return (
      <Fragment>
        <ThemeRichText body={bodyText} />
      </Fragment>
    )
  }
}

export default RichText;
