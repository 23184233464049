import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Block from 'components/Block';
import Footer from 'components/Footer';
import Meta from 'components/Meta';
import { variablesToObject } from 'lib/variablesToProps';
import Layout from 'components/Layouts/Default';
import { styled } from 'components/Theme/Styles';
import Headline from 'components/Theme/Headline';


const ClosebyWrapper = styled.div`
  width:100%;
  height:80vh;
  iframe {
    width:100%;
    border:0;
    height:80vh;
  }
`;

const Hero = styled.div`
  display: flex;
  flex-direction: row;
  font-size:6rem;
  background-color:${p => p.theme.black};
  height:50vh;
  align-items:center;
  justify-content:center;
  text-align:center;
`;

const Subnav = styled.div`
  display: flex;
  flex-direction: row;
  font-size:2rem;
  background-color:${p => p.theme.lightGrey};
  a {
    padding:2.5rem;
  }
`;

const SubnavLink = styled.a`
  cursor:pointer;
`;


class Dealers extends Component {
  constructor(props) {
    super(props)
    this.modal = null;
  }


  state = {
    rep: true, 
    dealer: false,
  };

  openDealer = () => {
    this.setState({ rep: false, dealer: true });
    console.log("test");
  };

  openRep = () => {
    this.setState({ rep: true, dealer: false });
  };

  render() {
    const {
      title,
      description,
      pageImage,
      blocks,
      variables,
      meta } = this.props.data.contentfulPage;

    let descriptionText = description && description.description;
    let pageVariables = variablesToObject(variables, 'page').page;
    let headerProps = pageVariables && pageVariables.header;

    return (
      <Layout>
        <Meta title={title}
              description={descriptionText}
              searchEngineFeatures={meta.searchEngineFeatures}
              robotsAdvance={meta.robotsAdvance}
              canonicalUrl={meta.canonicalUrl}
              pageImage={pageImage}
              variables={meta.variables}
              schemaWebpage
              schemaOrganization
              openGraph
              twitter />

        <Header {...headerProps} />

        <Hero>
          <Headline h2 color="white">Where to buy</Headline>
        </Hero>        

        <Subnav>
          <SubnavLink onClick={() => this.openRep()}>Reps</SubnavLink>
          <SubnavLink onClick={() => this.openDealer()}>Dealers</SubnavLink>
        </Subnav>

        <ClosebyWrapper hidden={!this.state.rep}>
          <iframe src="https://www.closeby.co/embed/dd5ddd4d07b277733eeca35e85f9f778?category=Rep" title="Find a Rep" ></iframe>
        </ClosebyWrapper>
        
        <ClosebyWrapper hidden={!this.state.dealer}>
          <iframe src="https://www.closeby.co/embed/dd5ddd4d07b277733eeca35e85f9f778?category=Dealer" title="Find Dealers" ></iframe>
        </ClosebyWrapper>

        {(blocks || []).map((block, i) => {
          return <Block key={`block_${i}`} {...block} />
        }) }

        <Footer />
      </Layout>
    );
  }
}

export default Dealers;

// language=GraphQL
export const pageQuery = graphql`
  query DealersQuery {
    contentfulPage(handle: { eq: "where-to-buy" }) {
      title
      description {
        description
      }
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }
      showSocialButtons
      blocks {
        ...Blocks
      }
      variables {
        key
        value
      }
    }
  }
`;


