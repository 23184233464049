import React, { Component } from 'react';
import variablesToProps from 'lib/variablesToProps';
import Helmet from 'react-helmet';

class SchemaPerson extends Component {

  render() {
    const {
      schemaWebpage
    } = this.props

    const {
      name,
      url,
      image,
      sameAs
    } = schemaWebpage


    let schema = {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Person",
        "@id": "#person",
        "name": `${name}`,
        "url": `${url}`,
        "image": `${image}`,
        "sameAs": `${sameAs}`,
      })
    };

    return (
      <Helmet script={[schema]} />
    )

  }
}


export default variablesToProps('schemaPerson')(SchemaPerson);
