global.siteTitle = "";
global.siteTitleAlt = "";
global.siteDescription = "";
global.siteUrl = ""; // No trailing slash
global.shareImage = "";
global.shareImageWidth = "";
global.shareImageHeight = "";

const paddings = {
  lg: 8,
  md: 6,
  sm: 4,
  xs: 2,
}

export const fontSystemFamilies = ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Lato", "Ubuntu", "Cantarell", "Helvetica Neue", "sans-serif"]
export const fontFamilies = ["Proxima Nova", ...fontSystemFamilies]

const theme = {
  // colors,
  white: '#FFFFFF',
  mediumWhite: '#F8F8F8',
  darkWhite: '#eeeeee',
  lightGrey: '#efefef',
  grey: '#c6c8ca',
  mediumGrey: '#545454',
  darkGray: '#363636', // deprecated
  darkGrey: '#363636',
  black: '#2b2b2b',
  lightSuccess: '#FFFFFF',
  darkSuccess: '#000000',
  brandPrimary: '#075EDB',
  brandPrimaryAlt: '#2B5E9D',
  brandPrimaryLight: '#edeaff',
  brandSecondary: '#171F26',
  brandSecondaryLinks: '#92A5B1',
  brandSecondaryHover: '#eaebf5',
  brandSecondaryBorder: '#2F363C',
  brandInfo: '#5b8edc',
  brandSuccess: '#5b8edc',
  brandWarning: '#ec663c',
  brandDanger: '#ec663c',
  brandLight: '#C2C3C4',
  headerColor: '#5b8edc',

  // general (in pixels)
  headerHeight: 6,
  maxWidth: 120,
  padding: paddings,

  blockPaddingLarge: paddings.lg,
  blockPaddingMedium: paddings.md,
  blockPaddingSmall: paddings.sm,
  blockPaddingExtraSmall: paddings.xs,

  // typography (in rem)
  h1FontSize: 7,
  h2FontSize: 4.5,
  h3FontSize: 2.488,
  h4FontSize: 1.728,
  h5FontSize: 1.44,
  h6FontSize: 1.44,

  navItemFontSize: 1.3,

  fontFamilyTitle: `'${fontFamilies.join("', '")}'`,
  fontFamily: `'${fontFamilies.join("', '")}'`,

  // forms
  formLabelFontSize: 1.2,
  formInputFontSize: 1.4,
  formInputLargeFontSize: 2,
  formInputBorderWidth: 0.1,
  formInputWidth: 40,
  formPlaceholderColor: '#000',

  spacing: (keyword) => {
    switch (keyword) {
      case 'Extra Small':
        return paddings.xs
      case 'Small':
        return paddings.sm
      case 'Medium':
        return paddings.md
      case 'Large':
        return paddings.lg
      default:
        return 0
    }
  }


}

export default theme
