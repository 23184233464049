import React, { Component } from 'react'
import { styled, media, css } from 'components/Theme/Styles';
import TextField from './TextField';
import PhoneField from './PhoneField';
import TextAreaField from './TextAreaField';
import Checkboxes from './Checkboxes';
import SelectBox from './SelectBox';
import variablesToProps from 'lib/variablesToProps';
import { graphql } from 'gatsby'

const widthMixin = css`
  ${ p => {
    if (p.direction === 'Vertical') {
      return verticalWidthMixin;
    } else {
      return `100% !important`
    }
  }}
`;

const verticalWidthMixin = css`
  ${ p => {
    if (p.hasDoubleVerticalColumns && p.type !== 'TextArea') {
      return '50%'
    } else if (p.hasDoubleVerticalColumns && p.type === 'TextArea') {
      return '100%'
    } else {
      return `100% !important`
    }
  }}
`;

const marginMixin = css`
  ${ p => {
  if (p.direction === 'Vertical') {
    return p.type === 'Checkboxes' ? '1rem 0 0 0' : '1rem 0';
  } else {
    return '0 3rem;'
  }
}}
`;

const subCopyRequiredMixin = css`
  position:relative;
  &:after {
    font-size:0.8rem;
    content: "REQUIRED";
    position: absolute;
    left:0.5rem;
    top:0rem;
    color:red;
    font-weight:bold;
  }
`

const FieldWrapper = styled.div`
  margin:${marginMixin};
  width:${widthMixin};
  padding:0 1rem;
  
  
  
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const FieldTypeWrapper = styled.div`
  ${p => p.type === 'Checkboxes' ? null : `border:${p.theme.formInputBorderWidth}rem solid #333`};
  padding:0.5rem;
  background-color: #ffffff;
  border-radius:0.5rem;
`;

const SubCopy = styled.label`
   height:${p => p.theme.formLabelFontSize + 1}rem;
   font-size:${p => p.theme.formLabelFontSize}rem;
   display:flex;
   flex-direction:row;
   margin-top:0.3rem;
   align-items: ${p => p.textAlign ? p.textAlign : 'right'};
   justify-content: ${p => p.textAlign ? p.textAlign : 'right'};
   text-align: ${p => p.textAlign ? p.textAlign : 'right'};
   ${p => p.required ? subCopyRequiredMixin : null};
`;

const FieldType = (props) => {
  let { type } = props

  switch (type) {
    case 'Phone':
      return <PhoneField {...props} />
    case 'TextArea':
      return <TextAreaField {...props} />
    case 'Checkboxes':
      return <Checkboxes {...props} />
    case 'Select':
      return <SelectBox {...props} />
    default:
      return <TextField {...props} />
  }
}

class FormField extends Component {
  render() {
    const { name, type, required, direction, hasDoubleVerticalColumns, field } = this.props;
    let label = `${this.props.label}${required ? '*' : ''}`
    let textVars = field && field.text

    return (
      <FieldWrapper direction={direction} type={type} hasDoubleVerticalColumns={hasDoubleVerticalColumns}>
        <FieldTypeWrapper type={type}>
          <FieldType {...this.props} label={label} />
        </FieldTypeWrapper>
        <SubCopy htmlFor={name} textAlign={textVars && textVars.align} required={required} type={type} />
      </FieldWrapper>
    )
  }
}

export default variablesToProps('field')(FormField);


// language=GraphQL
export const formFieldFragment = graphql`
  fragment Field on ContentfulFormField {
    __typename
    name
    type
    required
    helpText
    label
    options
    defaultValue
    variables {
      key
      value
    }
  }
`;