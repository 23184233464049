import React, { Component } from 'react'
import TextBox from 'components/Theme/TextBox';

class TextField extends Component {
  render() {
    const { name, label, value, field, required, change } = this.props
    let textVars = field && field.text

    return (
      <>
        <TextBox  type="text"
                  value={value}
                  autocomplete="off"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="none"
                  name={name}
                  required={required}
                  placeholder={label}
                  onChange={change}
                  textSmall={textVars && textVars.small}
                  textAlign={textVars && textVars.align} />
      </>
    )
  }
}

export default TextField;
