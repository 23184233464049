import React, { Component } from 'react';
import Link from './Link';
import { styled, media, css } from './Styles';

const backgroundColor = css`
  ${ p => {
    let color = p.invert ? p.theme.black : p.theme.brandPrimary
    color = p.transparent ? 'transparent' : color
    return color
  }
}`;

const backgroundColorAlt = css`
  ${ p => {
  let color = p.invert ? p.theme.black : p.theme.brandPrimaryAlt
  color = p.transparent ? 'transparent' : color
  return color
}
  }`;

const fontColor = css`
  ${ p => {
    return p.theme.white
  }
}`;

const borderColor = css`
  ${ p => {
    let color = p.transparent ? (p.invert ? p.theme.black : p.theme.white) : 'transparent'
    return color
  }
}`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  outline:none;
  position:relative;
`;

const TextLink = styled.div`
  font-size:1.8rem;
  color: ${p => p.theme.brandPrimary};
`;

export const ThemeButton = styled.button`
  text-align: center;
  color: ${fontColor};
  background: linear-gradient(${backgroundColor} 0, ${backgroundColorAlt} 100%);
  text-transform: uppercase;
  min-height: auto;
  min-width: auto;
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.small ? 1 : 1.2}rem;
  letter-spacing: 0.05rem;
  padding: 1rem ${p => p.arrow ? `3rem` : `2rem`} 1rem 2rem; // em for relative padding based on font size
  display: inline-flex;
  align-items: center;
  cursor:pointer;
  position:relative;
  border: 0.1rem solid ${borderColor};
  border-radius:1rem;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform opacity;
  transition-property: transform opacity;

&:hover, &:focus, &:active {
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  opacity:0.9;
}
  &:focus {
    outline:0;
  }
  ${media.lessThan("mobile")`
    font-size: ${p => p.small ? 1.2 : 1.4}rem;
  `}
`;

const ButtonAppearance = (p) => (
  <ThemeButton small={p.small} transparent={p.transparent} invert={p.invert} aria-label={p.children} className={p.className}>
    {p.children}
  </ThemeButton>
)

const TextAppearance = (p) => (
  <TextLink>
    {p.children}
  </TextLink>
)

// ThemeButton
class Button extends Component {

  render() {
    const { onClick, text } = this.props;

    return (
      <ButtonLink to={ this.props.to } onClick={onClick}>
        {text ? <TextAppearance {...this.props} /> : <ButtonAppearance {...this.props} /> }
      </ButtonLink>
    )
  }
}

export default Button;
