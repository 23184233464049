import React, { Component } from 'react';
import { styled, media } from 'components/Theme/Styles';
import ThemeHeadline from 'components/Theme/Headline';

const Title = styled(ThemeHeadline)`
  margin-bottom:0;
`;

const ShortDescrip = styled.div`
  font-size:1.8rem;
`;

const Series = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width:100%;
  margin:2.5rem;

  ${media.lessThan("tablet")`
    flex-direction: column;
    justify-content: center;
  `}
`;

const Products = styled.div`
  display:flex;
  flex-direction: row;
  width:75%;
  justify-content: flex-start;
  text-align: center;
  align-items: flex-start;
  flex-wrap:wrap;

  ${media.lessThan("tablet")`
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width:100%;
  `}
`;

const Label = styled.div`
  background-color:#f9f9f9;
  width:25%;
  padding:3rem;

  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const SeriesView = (props) => (
  <Series>
    <Label id={props.handle}>
      <Title h3>{props.title}</Title>
      <ShortDescrip h4>{props.description}</ShortDescrip>
    </Label>
    <Products>
      { props.children }
    </Products>
  </Series>
)

export  default SeriesView
