import React, { Component, Fragment } from 'react'
import { styled, media } from 'components/Theme/Styles';
import ThemeBlock, { Section } from 'components/Theme/Block';
import ThemeButton from 'components/Theme/Button';
import { GatsbyImage } from "gatsby-plugin-image"
import Headline from 'components/Theme/Headline';
import { FilePdf } from 'styled-icons/fa-solid/FilePdf'
import SpecTable from 'components/Product/SpecTable';
import Certs from 'components/Theme/Certs';

const SpecImages = styled.ul`
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin:2.5rem 0;
  

  ${media.lessThan("tablet")`
    flex-direction: column;
  `}
`;

const SpecSheet = styled(ThemeButton)`

`;

const SpecHeadline = styled(Headline)`
  width:auto;
  margin:5rem;
  padding:2rem;
  
  text-align:center;
`;


const CertBlock = styled(ThemeBlock)`
  margin-bottom:10rem;
`;
class Specs extends Component {

  render() {
    const { specs, specSheet, images, certifications } = this.props

    return (
      <Fragment>
        <ThemeBlock id="specs" height="Auto">
          <Section justify="Top" align="Center" innerPadding="Small" innerWidth="100%">
            <SpecHeadline h2>Specs</SpecHeadline>
            { specSheet && specSheet.file &&
              <SpecSheet to={`https://${specSheet.file.url}`} target="_blank">Download Spec Sheet</SpecSheet>
            }

            {images &&
              <SpecImages>
                {(images||[]).map((image, i) => {
                  return (
                    <li key={`spec_image_${i}`}>
                      <GatsbyImage image={image.gatsbyImageData} data-aos="zoom-in-up" />
                    </li>
                  )
                })}
              </SpecImages>
            }

            <SpecTable specs={specs} />
          </Section>
        </ThemeBlock>

        { certifications &&
          <CertBlock>
            <Section width="70%">
              <Certs certifications={certifications} />
            </Section>
          </CertBlock> }

      </Fragment>
    )
  }
}

export default Specs;
