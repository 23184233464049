import React, { Component } from 'react';
import { styled } from 'components/Theme/Styles';
import { KeyboardArrowLeft } from 'styled-icons/material'

const BackButton = styled.a`
  position: absolute;
  left: 5.5rem;
  top: 0rem;
  line-height: 2.5rem;
  cursor: pointer;
  font-size:1.8rem;
`;

const BackArrow = styled(KeyboardArrowLeft)`
  display: block;
  position: relative;
  top: 3.2rem;
  left: -3.5rem;
  font-size:3.2rem;
`;

class SimpleBackButton extends Component {

  back() {
    window.history.back()
  }

  render() {
    return (
      <div>
        <BackButton onClick={ this.back }><BackArrow /> Back</BackButton>
      </div>
    );
  }
}



export default SimpleBackButton;
