import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Config from '../../config'
import SchemaOrganization from './SchemaOrganization';
import SchemaWebpage from './SchemaWebpage';
import SchemaArticle from './SchemaArticle';
import SchemaItemList from './SchemaItemList';
import SchemaPerson from './SchemaPerson';
import OpenGraph from './OpenGraph';
import Twitter from './Twitter';
import { currentHref } from 'lib/currentPath'

class Meta extends Component {

  setRobots(searchEngineFeatures, robotsAdvance) {
    let content = [];

    if (robotsAdvance === "No Index") {
      content.push("noindex");
    }
    if (searchEngineFeatures && !searchEngineFeatures.includes("Follow Links")) {
      content.push("nofollow");
    }

    if (robotsAdvance === "No Archive") {
      content.push("noarchive");
    }

    if (robotsAdvance === "No Image Index") {
      content.push("noimageindex");
    }

    if (robotsAdvance === "No Snippet") {
      content.push("nosnippet");
    }

    if (content.length === 0) {
      content.push('all')
    }

    return content.join(',');
  }

  render() {
    const {
      title,
      description,
      searchEngineFeatures,
      robotsAdvance,
      canonicalUrl,
      pageImage,
      variables,
      schemaOrganization,
      schemaWebpage,
      schemaPerson,
      schemaArticle,
      schemaItemList,
      openGraph,
      twitter,
      createdAt,
      updatedAt
    } = this.props;

    let robots = this.setRobots(searchEngineFeatures, robotsAdvance);

    return (
      <Fragment>
        <Helmet
          htmlAttributes={{ lang : 'en' }}
          title={`${title} | Proluxe`}
          meta={[
            { name: 'description', content: description || Config.get("metaDescription") },
            { name: 'robots', content: robots }
          ]}
          link={[
            { rel: 'canonical', href: canonicalUrl || currentHref() },
            { rel: 'shortcut icon', href: '/app-icon.png', type: 'image/png' },
          ]}
        />

        {schemaOrganization &&
          <SchemaOrganization title={title} description={description} pageImage={pageImage} variables={variables} />}

        {schemaWebpage &&
          <SchemaWebpage title={title} description={description} variables={variables} />}

        {schemaArticle &&
          <SchemaArticle title={title} description={description} createdAt={createdAt} updatedAt={updatedAt} variables={variables} />}

        {schemaItemList &&
          <SchemaItemList variables={variables} />}

        {schemaPerson &&
          <SchemaPerson variables={variables} />}

        {openGraph &&
          <OpenGraph title={title} description={description} pageImage={pageImage} variables={variables} />}

        {twitter &&
          <Twitter title={title} description={description} pageImage={pageImage} canonicalUrl={canonicalUrl} variables={variables} />}
      </Fragment>
    )
  }
}

export default Meta;

export const metaFragment = graphql`
  fragment Meta on ContentfulComponentMeta {
    searchEngineFeatures
    robotsAdvance
    canonicalUrl
    variables {
      key
      value
    }
  }
`;
