import React, { Component } from 'react';
import { styled, css, media } from './Styles';

const defaults = css`
  color: ${p => p.color || p.theme.black};
  font-weight: ${p => p.weight || '300'};
  text-transform:${p => p.transform || 'none'};
  margin-right:0;
  margin-left:0;
  margin-top:${p => p.theme.spacing(p.marginTop) || 0 }rem;
  margin-bottom:${p => p.theme.spacing(p.marginBottom) || 1 }rem;
  font-style: normal;
  width:100%;
  
  ${p => p.backgroundColor && `
    background-color: ${p.backgroundColor} !important;
  `};
`;

const H1 = styled.h1`
  font-size: ${p => p.theme.h1FontSize}rem;
  line-height:${p => p.theme.h1FontSize}rem;
  // line-height:${p => p.theme.h1FontSize * 1.5}rem;
  ${defaults}

  ${media.lessThan("tablet")`
    
  `}
`;

const H2 = styled.h2`
  font-size: ${p => p.theme.h2FontSize}rem;
  line-height: ${p => p.theme.h2FontSize * 1}rem;
  ${defaults}
`;

const H3 = styled.h3`
  font-size: ${p => p.theme.h3FontSize}rem;
  line-height:${p => p.theme.h3FontSize * 1.5}rem;
  ${defaults}
`;

const H4 = styled.h4`
  font-size: ${p => p.theme.h4FontSize}rem;
  line-height:${p => p.theme.h4FontSize * 1.5}rem;
  letter-spacing:0.1rem;
  ${defaults}
`;

const H5 = styled.h5`
  font-size: ${p => p.theme.h5FontSize}rem;
  line-height:${p => p.theme.h5FontSize * 1.5}rem;
  ${defaults}
`;

const H6 = styled.h6`
  font-size: ${p => p.theme.h6FontSize}rem;
  line-height: ${p => p.theme.h6FontSize * 1.5}rem;
  ${defaults}
`;

const Default = styled.div`
  font-size: 2rem;
  padding-bottom:2rem;
  z-index:1;
`

class ThemeHeadline extends Component {

  render() {
    const { h1, h2, h3, h4, h5, h6, size, children, noFade, backgroundColor } = this.props;

    let noHeadline = (!h1 && !h2 && !h3 && !h4 && !h5 && !h6 && !size)

    return (
      <>
        { (h1 || size === 'h1') && <H1 data-aos={noFade ? '' : 'fade-down'} {...this.props}>{children}</H1> }
        { (h2 || size === 'h2') && <H2 data-aos={noFade ? '' : 'fade-down'} data-aos-delay="400" {...this.props}>{children}</H2> }
        { (h3 || size === 'h3') && <H3 data-aos={noFade ? '' : 'fade-down'} data-aos-delay="800" {...this.props}>{children}</H3> }
        { (h4 || size === 'h4') && <H4 data-aos={noFade ? '' : 'fade-down'} data-aos-delay="800" {...this.props}>{children}</H4> }
        { (h5 || size === 'h5') && <H5 data-aos={noFade ? '' : 'fade-down'} data-aos-delay="600" {...this.props}>{children}</H5> }
        { (h6 || size === 'h6') && <H6 data-aos={noFade ? '' : 'fade-down'} data-aos-delay="800" {...this.props}>{children}</H6> }
        { noHeadline && <Default>{children}</Default> }
      </>
    );
  }
}


export default ThemeHeadline;
