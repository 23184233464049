// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pages-find-service-js": () => import("./../../../src/pages/pages/find-service.js" /* webpackChunkName: "component---src-pages-pages-find-service-js" */),
  "component---src-pages-pages-get-service-js": () => import("./../../../src/pages/pages/get-service.js" /* webpackChunkName: "component---src-pages-pages-get-service-js" */),
  "component---src-pages-pages-installations-js": () => import("./../../../src/pages/pages/installations.js" /* webpackChunkName: "component---src-pages-pages-installations-js" */),
  "component---src-pages-pages-maintenance-js": () => import("./../../../src/pages/pages/maintenance.js" /* webpackChunkName: "component---src-pages-pages-maintenance-js" */),
  "component---src-pages-pages-parts-js": () => import("./../../../src/pages/pages/parts.js" /* webpackChunkName: "component---src-pages-pages-parts-js" */),
  "component---src-pages-pages-returns-js": () => import("./../../../src/pages/pages/returns.js" /* webpackChunkName: "component---src-pages-pages-returns-js" */),
  "component---src-pages-pages-serial-number-lookup-js": () => import("./../../../src/pages/pages/serial-number-lookup.js" /* webpackChunkName: "component---src-pages-pages-serial-number-lookup-js" */),
  "component---src-pages-pages-serial-number-lookup-show-js": () => import("./../../../src/pages/pages/serial-number-lookup/show.js" /* webpackChunkName: "component---src-pages-pages-serial-number-lookup-show-js" */),
  "component---src-pages-pages-service-js": () => import("./../../../src/pages/pages/service.js" /* webpackChunkName: "component---src-pages-pages-service-js" */),
  "component---src-pages-pages-where-to-buy-js": () => import("./../../../src/pages/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-pages-where-to-buy-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/Legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-list-js": () => import("./../../../src/templates/ProductList.js" /* webpackChunkName: "component---src-templates-product-list-js" */)
}

