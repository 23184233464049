import React, { Component } from 'react';
import Link from 'components/Theme/Link';
import { styled, media } from 'components/Theme/Styles';
import { Youtube, Facebook, Twitter, Linkedin, Instagram } from 'styled-icons/fa-brands'
import { Send } from 'styled-icons/feather'
import { ThemeButton } from '../Theme/Button';
import TextBox from 'components/Theme/TextBox';
import Logo from 'components/Theme/Logo';

const Signup = styled.div`
  padding:5rem;
  background-color:${p => p.theme.black};
  display:flex;
  justify-content: center;
  align-items: center;
  color:white;
  flex-direction: column;
  
`;

const SignupCopy = styled.div`
  font-size:${p => p.theme.h4FontSize}rem;
  font-weight:300;
  margin-bottom:2rem;
  align-items: center;
  text-align:center;
`;

const SignupForm = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:1rem;
  justify-content: center;
  text-align:center;
  form {
    display:block;
    margin-bottom:0;
  }
`;

const SubmitButton = styled(ThemeButton)`
  position:relative;
  top:-0.2rem;
  
  ${media.lessThan("tablet")`
    margin-top:3rem;
  `}
`;

const EmailBox = styled(TextBox)`
  border:${p => p.theme.formInputBorderWidth}rem solid ${p => p.theme.brandPrimaryLight};
  width:30rem;
  height:3.2rem;
  margin-right:2rem;
  position:relative;
  top: 0.3rem;
  text-indent: 1rem;
  font-size:${p => p.theme.h4FontSize}rem;
  color:${p => p.theme.black};
  &::placeholder {
    color:${p => p.theme.black};
  }

  ${media.lessThan("tablet")`
    text-align:center;
    height:4rem;
    top:0rem;

    margin-right:1rem;
    &::placeholder {
      text-align:center;
    }
  `}
`

const FooterWrapper = styled.div`
  background-color: ${props => props.theme.brandSecondary};
  width: 100%;
  color: ${props => props.theme.brandSecondaryLinks};
  ${media.greaterThan("tablet")`
    height: 50rem;
  `}
`;

const SocialSection = styled.div`
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  width: 100%;
  border-bottom: 0.1rem solid ${props => props.theme.brandSecondaryBorder};

  ${media.lessThan("tablet")`
    justify-content:center;
  `};
`;

const SocialSpacer = styled.div`
  ${media.greaterThan("tablet")`
    flex: 1 1 auto;
    width: auto;
  `}
`
const SocialLink = styled.div`
  border-left: 0.1rem solid ${props => props.theme.brandSecondaryBorder};
  width: 4.5rem;
  flex: 0 0 auto;
  font-size: 2.8rem;
  color: ${props => props.theme.brandSecondaryLinks};
  text-align: center;
  line-height: 2.5rem;

  a {
    color: ${props => props.theme.white};
  }



  ${media.lessThan("tablet")`
    &:nth-child(2) {
      border-left: none;
    }
  `};

  svg {
    fill: ${props => props.theme.white};
    width:2rem;
    height:2rem;
    position:relative;
    top:0.4rem;
  }
`;

const Nav = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3rem;
  flex-direction: row;
  justify-content: space-around;
  ${media.lessThan("tablet")`
    flex-direction: column;
  `};
`;

const Section = styled.div`
  margin: 0 2.5rem;
  

  ${media.lessThan("tablet")`
    margin:0 auto;
    width: 30rem;
  `};

`;

const Header = styled.h3`
  width: 100%;
  text-align: left;
  color: #ccc;
  font-weight: 300;
  font-style: normal;
  font-size: 1.3rem;
  padding-bottom: 2.25rem;
  border-bottom: 0.1rem solid ${props => props.theme.brandSecondaryBorder};
  margin-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  text-transform: uppercase;

  ${media.lessThan("tablet")`
    padding-bottom:1rem;
    margin-bottom: 2rem;
    text-align:center;
  `};
`;

const Links = styled.ul`
  list-style-type: none;
  margin: 1.5rem 0;
  padding: 0;
  text-align: left;
  font-size: 1.6rem;
  line-height: 3rem;
  font-weight:300;

  a {
    transition: color 0.2s;
    -webkit-transition: color 0.2s;
    color: #92A5B1;
    &:hover {
      color:#ffffff;
    }
  }

  ${media.lessThan("tablet")`
    margin: 1rem 0 3rem 0;
    line-height: 3rem;
    font-weight:400;
    text-align:center;
  `};
`;


const Legal = styled.div`
  border-top: 0.1rem solid ${props => props.theme.brandSecondaryBorder};
  display: flex;
  width: 100%;
  margin-top: 3rem;
  padding-top: 3rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;

  ${media.lessThan("tablet")`
    flex-direction:column-reverse;
    justify-content: space-around;
  `};
`;

const LegalLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-left:5rem;

  ${media.lessThan("tablet")`
    flex-direction:row-reverse;
    margin:2.5rem;
    justify-content: space-between;
  `};
`;

const LegalLink = styled.div`
  a {
    color: ${p => p.theme.brandSecondaryLinks}
  }
  ${media.greaterThan("tablet")`
    margin-right: 5rem;
  `};
`;

const TradeMark = styled.div`
  ${media.greaterThan("tablet")`

  `};
`;

const LegalRight = styled.div`
display:flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: left;
  margin-right:5rem;

  ${media.lessThan("tablet")`
    flex-direction:column;
    margin:2.5rem;
    text-align:center;
  `};
`;

const Flag = styled.img`
  height: 1.4rem;
  margin-left: 0.5rem;
  position: relative;
  top: 0.2rem;
`;

const Address = styled.div`
  line-height: 2rem;
  color: #fafafa;
`;

const TollFree = styled.div`
  margin-top:2rem;
`;


class Footer extends Component {
  render() {

    return (
      <div>
        {this.props.display !== 'No Footer' &&
          <div>
            {!this.props.hideNewsletterSignup &&
              <Signup>
                <SignupCopy>
                  Get the latest news, product updates, and more.
                </SignupCopy>
                <SignupForm>
                  <form data-netlify="true"
                        name="Newsletter Signup"
                        method="POST"
                        action="/email-confirmation">
                    <input type="hidden" aria-label="form-name" name="form-name" value="Newsletter Signup"/>
                    <EmailBox type="email" name="email" required placeholder="Email Address"/>
                    <SubmitButton type="submit" arrow={false} alternative={true} small={true}>Signup</SubmitButton>
                  </form>
                </SignupForm>
              </Signup>
            }
            <FooterWrapper>
              <SocialSection>
                <SocialSpacer/>
                <SocialLink><Link to="https://www.instagram.com/proluxehq/"><Instagram
                  alt="Proluxe Instagram"/></Link></SocialLink>
                <SocialLink><Link to="https://www.youtube.com/channel/UCuUi2c1CkYytANshUGuQshA"><Youtube
                  alt="Proluxe YouTube Channel"/></Link></SocialLink>
                <SocialLink><Link to="https://www.linkedin.com/company/doughpro"><Linkedin
                  alt="Proluxe LinkedIN Page"/></Link></SocialLink>
              </SocialSection>


              <div>
                <Nav>
                  <Section>
                    <Header>Corporate Headquarters</Header>
                    <Links>
                      <li>
                        <Address>
                          <div>20281 Harvill Ave</div>
                          <div>Perris, CA 92570</div>

                          <TollFree>Toll Free: 1-800-624-6717</TollFree>
                        </Address>
                      </li>
                    </Links>
                  </Section>
                  <Section>
                    <Header>Company</Header>
                    <Links>
                      <li><Link to="/pages/about">About Us</Link></li>
                      <li><Link to="/blog">Blog</Link></li>
                      <li><Link to="/pages/events">Events</Link></li>
                    </Links>
                  </Section>
                  <Section>
                    <Header>How to Buy</Header>
                    <Links>
                      <li><Link to="/pages/contact-us">Contact Us</Link></li>
                      <li><Link to="/pages/where-to-buy">Find a Rep/Dealer</Link></li>
                      { /* <li><Link to="/cities">Browse All Cities</Link></li> */ }
                    </Links>
                  </Section>
                  <Section>
                    <Header>Industries</Header>
                    <Links>
                      <li><Link to="/pages/industries/restaurants">Restaurants</Link></li>
                      <li><Link to="/pages/industries/hospitality">Hospitality</Link></li>
                      <li><Link to="/pages/industries/supermarkets">Supermarkets</Link></li>
                      <li><Link to="/pages/industries/education">Education</Link></li>
                      <li><Link to="/pages/industries/convenience-stores">Convenience Stores</Link></li>
                      <li><Link to="/pages/industries/business">Business</Link></li>
                    </Links>
                  </Section>
                  <Section>
                    <Header>Service &amp; Support</Header>
                    <Links>
                      <li><Link to="/pages/find-service">Find Service</Link></li>
                      <li><Link to="/pages/maintenance">Maintenance</Link></li>
                      <li><Link to="/pages/parts">Find Parts</Link></li>
                    </Links>
                  </Section>
                </Nav>

                <Legal>
                  <LegalLeft>
                    <Logo size={'3.4rem'} color={'p_icon'} />
                    
                  </LegalLeft>
                  <LegalRight>
                    <LegalLink><TradeMark>&copy; Proluxe {new Date().getFullYear()}</TradeMark></LegalLink>
                    <LegalLink><Link to="/terms">Terms of use</Link></LegalLink>
                    <LegalLink><Link to="/privacy">Privacy Policy</Link></LegalLink>
                    
                  </LegalRight>
                </Legal>
              </div>
            </FooterWrapper>
          </div>
        }
      </div>
    );
  }
}

export default Footer;
