import React, { Fragment, Component } from 'react';
import ThemeHeadline from 'components/Theme/Headline';

const Marquee = (props) => (
  <span style={{display: 'inline-block'}} id={props.id}>
    <span style={{borderRight: '0.3rem solid #ccc'}}>
      {props.value}
    </span>
  </span>
)

class MarqueeHeadline extends Component {

  state = {
    txt: '',
  }

  isDeleting = false
  loopNum = 0
  delta = 2000


  componentDidMount() {
    this.rotateText()
  }

  transformedCopy = () => {
    let regex = /(%.*%)/
    let childText = this.props.children;
    let textArray = childText.split(regex)

    return textArray.map((node) => {
      if (regex.test(node)) {
        return <Marquee key={Math.random()} value={this.state.txt} />
      } else {
        return node
      }
    })
  }

  rotateText = () => {
    let { txt } = this.state

    let i = this.loopNum % this.props.marqueeText.length;
    let fullTxt = this.props.marqueeText[i];

    this.setState({txt: this.isDeleting ? fullTxt.substring(0, txt.length - 1) : fullTxt.substring(0, txt.length + 1)})

    this.delta = 200 - Math.random() * 100;

    if (this.isDeleting) { this.delta /= 2; }

    if (!this.isDeleting && txt === fullTxt) {
      this.delta = 3000;
      this.isDeleting = true;
    } else if (this.isDeleting && txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      this.delta = 500;
    }

    setTimeout(() => { this.rotateText(); }, this.delta);
  }


  render() {
    const { textColor, size, transform, weight, marginBottom, marginTop } = this.props

    return (
      <Fragment>
        <ThemeHeadline size={size}
                       transform={transform}
                       marginTop={marginTop}
                       marginBottom={marginBottom}
                       weight={weight}
                       color={textColor}>{this.transformedCopy()}</ThemeHeadline>
      </Fragment>
    )
  }
}

export default MarqueeHeadline;
