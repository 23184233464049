import React, { Component } from 'react'
import { styled } from 'components/Theme/Styles';
import Button from 'components/Theme/Button';
import Link from 'gatsby-link';

const Wrapper = styled.div`
  position: relative;
  padding:0 1rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainLink = styled(Button)`\
  margin-left:1.5rem;
  font-weight: bold;
`;

class ContactUs extends Component {

  render() {
    const { inverse } = this.props
    return (
      <Wrapper inverse={inverse}>
        <Link to="/pages/contact-us">Contact Us</Link>
        <MainLink to="/pages/where-to-buy" small={true} arrow={false}>Where To Buy</MainLink>
      </Wrapper>
    );
  }
}

export default ContactUs;
