import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Block from 'components/Block';
import Footer from 'components/Footer';
import Meta from 'components/Meta';
import { variablesToObject } from 'lib/variablesToProps';
import ServiceLayout from 'components/Layouts/Service';
import Layout from 'components/Layouts/Default';
import { styled } from 'components/Theme/Styles';

const ClosebyWrapper = styled.div`
  width:100%;
  height:80vh;
  iframe {
    width:100%;
    border:0;
    height:80vh;
  }
`;

const FindServiceLayout = styled.div`
  padding:2rem;
`;

class FindService extends Component {
  constructor(props) {
    super(props)
    this.modal = null;
  }

  render() {
    const {
      title,
      description,
      pageImage,
      blocks,
      variables,
      meta } = this.props.data.contentfulPage;

    let descriptionText = description && description.description;
    let pageVariables = variablesToObject(variables, 'page').page;
    let headerProps = pageVariables && pageVariables.header;

    return (
      <Layout>
        <Meta title={title}
              description={descriptionText}
              searchEngineFeatures={meta.searchEngineFeatures}
              robotsAdvance={meta.robotsAdvance}
              canonicalUrl={meta.canonicalUrl}
              pageImage={pageImage}
              variables={meta.variables}
              schemaWebpage
              schemaOrganization
              openGraph
              twitter />

        <Header {...headerProps} />

        <ServiceLayout selected="Find Service" heroTitle="Find Service">
          <FindServiceLayout>
            {(blocks || []).map((block, i) => {
              return <Block key={`block_${i}`} {...block} />
            }) }

            <ClosebyWrapper>
              <iframe src="https://www.closeby.co/embed/dd5ddd4d07b277733eeca35e85f9f778?category=ASA" title="Find Service" ></iframe>
            </ClosebyWrapper>
          </FindServiceLayout>
        </ServiceLayout>

        <Footer />
      </Layout>
    );
  }
}

export default FindService;

// language=GraphQL
export const pageQuery = graphql`
  query FindServiceQuery {
    contentfulPage(handle: { eq: "find-service" }) {
      title
      description {
        description
      }
      pageImage {
        gatsbyImageData
      }
      meta {
        ...Meta
      }
      showSocialButtons
      blocks {
        ...Blocks
      }
      variables {
        key
        value
      }
    }
  }
`;


