import React, { Component } from 'react';
import { styled, media } from '../Styles';
import Validator from 'utils/validator';
import Video from '../Video';
import { GatsbyImage } from "gatsby-plugin-image"

const justify = (keyword) => {
  switch (keyword) {
    case 'middle':
      return 'center'
    case 'topish':
      return 'flex-start;'
    case 'top':
      return 'flex-start'
    case 'bottom':
      return 'flex-end;'
    case 'bottomish':
      return 'flex-end;'
    default:
      return 'center'
  }
}

const align = (keyword) => {
  switch (keyword) {
    case 'center':
      return 'center'
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end;'
    default:
      return 'flex-start'
  }
}

// const justifyIsh = (keyword) => {
//   switch (keyword) {
//     case 'topish':
//       return 'padding-top:10vh;'
//     case 'bottomish':
//       return 'padding-bottom: 10vh;'
//     default:
//       return null
//   }
// }

const SectionWrapper = styled.div`
  width:100%;
  flex: 1;
  display:flex;
  flex-direction: column;
  align-content: center;
  overflow: hidden;
  position:relative;
  // z-index:-2;
  // boilerplate stuff to make it easy to set background images on sections
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;


  background-color:${p => p.backgroundColor};

  ${media.lessThan("tablet")`
    ${p => (p.backgroundVideo || p.backgroundImage) && p.backgroundColor ? `
      background-color: ${p.backgroundColor}95;
    ` : null};

    ${p => (p.backgroundVideo || p.backgroundImage)  ? `
      min-height:50vh;
    ` : null};
  `}

  // justify="Top|Middle|Bottom"
  justify-content: ${p => p.justify ? justify(p.justify.toLowerCase()) : 'center' };
  // align="Left|Right|Center"
  align-items: ${p => p.align ? align(p.align.toLowerCase())  : 'center'};

  // justify="Topish|Bottomish"

`;

const SectionInner = styled.div`
  display:flex;
  flex-direction: column;
  align-content: center;
  padding:${p => p.theme.spacing(p.innerPadding)}rem;
  text-align: ${p => (p.align && p.align.toLowerCase() === 'center') ? 'center' : 'left'};

  ${p => p.align && p.align.toLowerCase() === 'center' && `
    align-items: center;
  `};

  ${media.greaterThan("tablet")`
    ${p => p.innerWidth ? `width: ${p.innerWidth};` : null}
  `};

  ${media.lessThan("tablet")`
    padding:${p => p.theme.spacing(p.innerPadding)}rem ${p => p.theme.spacing(p.innerPadding) / 2}rem;
    text-align:center;
    align-items:center;
    width:100%;
  `}
`;

const BackgroundWrapper = styled.div`
  display:flex;
  justify-content: center;
  position: absolute;
  //z-index:-2;
  width: calc(100% - ${p => p.theme.spacing(p.margin) * 2}rem);
  height: calc(100% - ${p => p.theme.spacing(p.margin) * 2}rem);
  
  ${media.lessThan("tablet")`
    height:100%;
    width:100%;
  `}
`;

const VideoTag = (props) => {
  return (props.valid && <Video src={props.src} opacity={props.backgroundOpacity} />) || null
}

const BackgroundTag = (props) => {
  let style = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    //zIndex: "-2",
  }

  return ( props.valid && <GatsbyImage alt="Proluxe" image={props.image} style={style} /> ) || null
}

class Section extends Component {
  isVideo() {
    const { backgroundVideo } = this.props
    return backgroundVideo && Validator.isVideo(backgroundVideo)
  }

  render() {
    const {
      children,
      backgroundVideo,
      backgroundImage,
      backgroundMargin,
      backgroundOpacity,
      align,
      innerPadding,
      innerWidth } = this.props
    return (
      <SectionWrapper className={this.props.className} {...this.props}>
        { (backgroundImage || backgroundVideo) &&
          <BackgroundWrapper margin={backgroundMargin}>
            <VideoTag valid={ this.isVideo() } src={ backgroundVideo } backgroundOpacity={ backgroundOpacity } />
            <BackgroundTag valid={ !this.isVideo() && backgroundImage } image={ backgroundImage } />
          </BackgroundWrapper>
        }

        <SectionInner align={align} innerPadding={innerPadding} innerWidth={innerWidth}>
          { children }
        </SectionInner>
      </SectionWrapper>
    )
  }
}

export default Section;
