import React, { Component } from 'react';
import ProductMenu from './DropDownMenu/ProductMenu';
import SupportMenu from './DropDownMenu/SupportMenu';
import _ from 'lodash';

class DropDownMenu extends Component {
  render() {
    const { navigation, inverse, onMenuOpen, onMenuClosed, activeMenu, menuOpen } = this.props
    console.log(navigation)
    return (
      <>
        { (navigation||[]).map((nav, i) => {
          
          return (
            <ProductMenu activeKey={_.camelCase(nav.topLevelName)}
                         navigationData={nav}
                         activeMenu={activeMenu}
                         inverse={inverse}
                         onMenuOpen={onMenuOpen}
                         onMenuClosed={onMenuClosed}
                         menuOpen={menuOpen}
                         key={`menu_${i}`} />
          )
        })}

        <SupportMenu activeKey="support" activeMenu={activeMenu}
                         inverse={inverse}
                         onMenuOpen={onMenuOpen}
                         onMenuClosed={onMenuClosed}
                         menuOpen={menuOpen}/>
      </>
    );
  }
}

export default DropDownMenu;
