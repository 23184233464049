import React, { Component } from 'react'
import { styled, media } from 'components/Theme/Styles';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import _ from 'lodash'

const Desktop = styled(DesktopNav)`
  font-family: ${p => p.theme.fontFamily};
  ${media.lessThan("tablet")`
    display:none;
  `}
`;

const Mobile = styled(MobileNav)`
  font-family: ${p => p.theme.fontFamily};
  ${media.greaterThan("tablet")`
    display:none;
  `}
`;

class Default extends Component {
    constructor(props) {
      super(props)
      this.repackagePayload()
    }
  
    repackagePayload() {
      this.navigation = []
      let flatContentfulPayload = this.flattenContentfulPayload()
      let flatShopifyCollections = this.flattenShopifyPayload()
      let topLevelNames = this.sortedTopLevelNames(flatContentfulPayload)


      return topLevelNames.map((name) => {
        return this.navigation.push({
          topLevelName: name,
          categories: this.sortedFlatCategories(flatContentfulPayload, name).map((category) => {
            
            return {
              name: category,
              lists: this.sortedFlatLists(flatContentfulPayload, category,
              name).map((list) => {
                return _.find(flatShopifyCollections, {title: list})
              })
            }  
          })
        })
      })
    }

    sortedTopLevelNames(flatContentfulPayload) {
      
      let preferredOrder = this.props.payload.contentfulGlobals.topLevelNavOrder;
      let unsortedNames = _.uniq(flatContentfulPayload.map((nav) => { return nav.topLevelName }))
      return unsortedNames.sort((a, b) => {
        return preferredOrder.indexOf(a) - preferredOrder.indexOf(b)
      })
    }

    sortedFlatCategories(flatContentfulPayload, topLevelName) {
      let preferredOrder = this.props.payload.contentfulGlobals.categoryListNavOrder;
      let unsortedCategories = this.flatCategories(flatContentfulPayload, topLevelName)
      
      return unsortedCategories.sort((a, b) => {
        return preferredOrder.indexOf(a) - preferredOrder.indexOf(b)
      })
    }


    sortedFlatLists(flatContentfulPayload, category, topLevelName) {
      let preferredOrder = this.props.payload.contentfulGlobals.collectionListNavOrder;
      let unsortedLists = _.compact(this.flatLists(flatContentfulPayload, category, topLevelName))
      return unsortedLists.sort((a, b) => {
        return preferredOrder.indexOf(a) - preferredOrder.indexOf(b)
      })
    }

    flatCategories(flatContentfulPayload, topLevelName) {
      return _.compact(_.uniq(flatContentfulPayload.map((navCategory) => { 
        return navCategory.topLevelName === topLevelName ? navCategory.category : null 
      })))
    }

    flatLists(flatContentfulPayload, category, topLevelName) {
      return _.compact(flatContentfulPayload.map((navList) => { 
        return navList.category === category && navList.topLevelName === topLevelName ? navList.shopifyCollectionName : null 
      }))
    }

    flattenContentfulPayload() {
      let edges = this.props.payload.allContentfulNavItem.edges
      return edges.map((edge) => { return edge.node })
    }

    flattenShopifyPayload() {
      let edges = this.props.payload.allShopifyCollection.edges
      return edges.map((edge) => { return edge.node })
    }

    render() {
      return (
        <>
          <Desktop navigation={this.navigation} {...this.props}  />
          <Mobile navigation={this.navigation} {...this.props} />
        </>
      );
    }
}



export default Default;
