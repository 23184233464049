import React, { Component } from 'react';
import { graphql } from 'gatsby'
import Form from 'components/Forms/Form';
import List from './List';
import Faq from './Faq';
import RichText from './RichText'
import RelatedPages from './RelatedPages'
import ProductCollections from './ProductCollections'

const BodyType = (props) => {
  switch (props.__typename) {
    case 'ContentfulSectionForm':
      return <Form {...props} />
    case 'ContentfulSectionList':
      return <List {...props} />
    case 'ContentfulSectionFaq':
      return <Faq {...props} />
    case 'ContentfulSectionRichText':
      return <RichText {...props} />
    case 'ContentfulSectionRelatedPages':
      return <RelatedPages {...props} />
    case 'ContentfulSectionProductCollections':
      return <ProductCollections {...props} />
    default:
      return null;
  }
}

class Body extends Component {

  render() {
    
    return (
      <>
        <BodyType {...this.props} />
      </>
    )
  }
}

export default Body;


export const faqFragment = graphql`
  fragment Faq on ContentfulSectionFaq {
    __typename
    faqs {
      question
      answer {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export const richTextFragment = graphql`
  fragment RichText on ContentfulSectionRichText {
    __typename
    body {
      childMarkdownRemark {
        html
      }
    }
  }
`;

