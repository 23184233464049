import React, { Component } from 'react';
import variablesToProps from 'lib/variablesToProps';
import Helmet from 'react-helmet';

class SchemaWebpage extends Component {
  render() {

    const {
      title,
      description,
      schemaWebpage
    } = this.props

    const {
      significantUrl,
      breadcrumbs
    } = schemaWebpage

    let schema = {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebPage",
        "headline": `${title}`,
        "name": `${title}`,
        "significantUrl": `${significantUrl}`,
        "description": `${description}`,
        "breadcrumb": {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": breadcrumbs.map((crumb) => {
            return ({
              "@type": "ListItem",
              "position": `${crumb.position}`,
              "item": {
                "@id": `${crumb.id}`,
                "name": `${crumb.name}`
              }
            })
          })
        }
      })
    };

    return (
      <Helmet script={[schema]} />
    )
  }
}

export default variablesToProps('schemaWebpage')(SchemaWebpage);