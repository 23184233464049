import React, { Fragment, Component } from 'react';
import { styled } from 'components/Theme/Styles';
import ProductMenu from './ProductMenu';
import SupportMenu from './SupportMenu';


const Menu = styled.ul`
  padding-bottom:5rem;
  color: #fff;
  width: 100vw;
  height:100vh;
  font-size: 1.4rem;
  font-weight: 300;
  font-style: normal;
  position: relative;
  background-color:${p => p.theme.black};

`;


const Drawer = styled.div`
  justify-content: center;
  z-index:10;
  opacity: 0;
  visibility: hidden;
  display:none;
  height:0;
  position:absolute;
  transform: translateY(0);
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  ${p => p.open ? `
    opacity: 1;
    visibility: visible;
    display: flex;
    height:auto;
  ` : null}
`;


class HamburgerMenu extends Component {


  render() {
    const { navigation, open } = this.props

    return (
      <Fragment>
        <Drawer open={open} >
          <Menu open={open}>
            <ProductMenu navigationData={navigation}/>
            <SupportMenu />
          </Menu>
        </Drawer>
      </Fragment>
    );
  }
}

export default HamburgerMenu;
