import React, { Component } from 'react';
import { styled } from 'components/Theme/Styles';
import { KeyboardArrowRight } from 'styled-icons/material'



const Wrapper = styled.ul`
  position: relative;
  width:100%;
  white-space: nowrap;
`;

const MainLink = styled.div`
  font-size: ${p => p.theme.h3FontSize}rem;
  color:#fff;
  
  display:flex;
  flex-direction: row;
  
  padding:1.5rem 3rem 1.5rem 3rem;
  cursor:pointer;
  &:visited {
    color:${p => p.theme.black};
  }
  ${p => p.depth  && `
    margin-left:${p.depth * 2}rem;
    font-size: ${p.theme.h4FontSize}rem;
    
    padding:1.5rem 1.5rem 1rem 1.5rem;
  `};

`;

const Arrow = styled(KeyboardArrowRight)`
  width:3rem;
  height:3rem;
  position:relative;
  top:0.2rem;
  transition: all 0.25s;
  transform: ${p => p.open ? 'rotate(90deg)' : 'rotate(0)'};
  ${p => p.depth === 2 && `
    width:2rem;
    height:2rem;
    right:3rem;
  `};
`;

const Menu = styled.div`
  opacity: 0;
  height:0;
  visibility: hidden;
  display:none;
  transform: translateY(0);
  transition: all 0.5s;
  will-change: transform;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  color:${p => p.theme.black};
  margin-bottom:3rem;
  padding-bottom:3rem;
  
  ${p => p.open ? `
    opacity: 1;
    height:auto;
    display:block;
    visibility: visible;
  ` : null}
`;

class HamburgerNav extends Component {
  state = {
    open: false
  };

  toggle = () => {
    this.setState({open: !this.state.open});
  };

  render() {
    const { text, children, depth } = this.props;
    let open = this.state.open || this.props.open;

    return (
      <Wrapper depth={depth}>
        <MainLink depth={depth} onClick={ this.toggle } open={open}>
        { !this.props.open && <Arrow open={open} depth={depth} /> } {text} 
        </MainLink>

        <Menu open={open}>
          { children }
        </Menu>
      </Wrapper>
    );
  }
}

export HamburgerNavItem from './HamburgerNavItem';
export default HamburgerNav;
