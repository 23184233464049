import React, { Component } from 'react';
import { styled, media } from 'components/Theme/Styles';
import ThemeHeadline from 'components/Theme/Headline';
import ThemeButton from 'components/Theme/Button';
import { GatsbyImage } from "gatsby-plugin-image";

const Product = styled.div`
  display: block;
  margin: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.lessThan("tablet")`
    margin-top: 3rem;
  `}
`;

const Name = styled(ThemeHeadline)`
  font-weight: bold;
  ${media.lessThan("tablet")`
    font-size: ${p => p.theme.h4FontSize}rem;
  `}
`;

const Image = styled(GatsbyImage)`
  max-width: 20rem;
`;

const Variants = styled.select`
  display: block;
  margin-bottom: 2rem;
  font-size: 1.4rem;
`;

class ProductCard extends Component {
  constructor(props) {
    super(props);
    // Initialize state with the first variant's SKU
    this.state = {
      selectedSku: props.product.variants[0].sku
    };
  }

  handleVariantChange = (event) => {
    this.setState({ selectedSku: event.target.value });
  }

  render() {
    const { product, to } = this.props;
    const { selectedSku } = this.state;

    return (
      <Product to={to}>
        { product.variants[0].image &&
          <Image alt={product.title} image={product.variants[0].image.gatsbyImageData} />
        }
        <Name h5>{product.title}</Name>

        <Variants onChange={this.handleVariantChange} value={selectedSku}>
          {product.variants.map((variant, i) => {
            return <option key={`variant_${i}`} value={variant.sku}>{variant.sku}</option>;
          })}
        </Variants>

        <ThemeButton to={`https://www.partstown.com/doughpro-proluxe/${selectedSku}/parts`} invert={true} small={true}>
          View Parts
        </ThemeButton>
      </Product>
    )
  }
}

export default ProductCard;
