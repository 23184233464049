import React, { Component } from 'react';
import { styled } from 'components/Theme/Styles';

const Phone = styled.input`
  width:100%;
  font-size:${p => p.theme.formInputFontSize}rem;
  border:0;
  background-color:transparent;
  &::placeholder {
    color:${p => p.theme.formPlaceholderColor};
  }
`;

class PhoneField extends Component {
  render() {
    const { name, value, label, required } = this.props;

    return (
      <div>
        <Phone type="tel"
               value={value}
               required={required}
               autocomplete="off"
               autocorrect="off"
               spellcheck="false"
               autocapitalize="none"
               name={name}
               placeholder={label} />
      </div>
    )
  }
}

export default PhoneField;
