import React, { Component } from 'react';
import Helmet from 'react-helmet';
import variablesToProps from 'lib/variablesToProps';

class Twitter extends Component {

  render() {
    const {
      title,
      description,
      pageImage,
      twitter
    } = this.props

    const {
      site,
      type,
      creator,
      canonicalUrl
    } = twitter

    let image = pageImage && pageImage.file && pageImage.file.url;

    return (
      <Helmet
        meta={[
          { key: 'twitter_card', property: 'twitter:card', content: 'summary' },
          { key: 'twitter_type', property: 'twitter:type', content: type },
          { key: 'twitter_site', property: 'twitter:site', content: site },
          { key: 'twitter_creator', property: 'twitter:creator', content: creator },
          { key: 'twitter_title', property: 'twitter:title', content: title },
          { key: 'twitter_description', property: 'twitter:description', content: description },
          { key: 'twitter_url', property: 'twitter:url', content: canonicalUrl },
          { key: 'twitter_image', property: 'twitter:image', content: 'https:' + image },
        ]}
      />
    )
  }
}


export default variablesToProps('twitter')(Twitter);
